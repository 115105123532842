import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useMultiSelectFilterNavigation from 'hooks/useMultiSelectFilterNavigation';

import StyledTagButton from 'components/common/StyledTagButton';
import colors from 'constants/colors';
import Box from 'components/common/Box';

const TccPartnerFilter = () => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const multiSelectFilter = useMultiSelectFilterNavigation();

  const queryValuesParams = ['GOLD', 'SILVER', 'BRONZE'];
  const translatedName = [t('GOLD'), t('SILVER'), t('BRONZE')];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      {queryValuesParams.map((queryValuesParam, i) => {
        return (
          <div key={i} style={{ overflow: 'hidden' }}>
            <StyledTagButton
              height={'30px'}
              width={'62px'}
              title={translatedName[i]}
              value={queryValuesParam}
              buttonColor={
                searchParams.get('partner')?.includes(queryValuesParam)
                  ? 'secondary'
                  : colors.neutral100
              }
              fontColor={
                searchParams.get('partner')?.includes(queryValuesParam)
                  ? 'white'
                  : colors.neutral500
              }
              fontColorOnHover={colors.neutral500}
              buttonColorHover={'secondary'}
              onClick={() => multiSelectFilter('partner', queryValuesParam)}
              sx={{ marginRight: '8px', marginBottom: '4px' }}
            />
          </div>
        );
      })}
    </Box>
  );
};

export default observer(TccPartnerFilter);
