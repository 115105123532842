import Box from '@mui/material/Box';
import Loading from 'components/common/Loading';

const AppLoading = () => (
  <Box display="flex" justifyContent="center" pt={'25vh'}>
    <Loading />
  </Box>
);

export default AppLoading;
