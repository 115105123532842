import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import colors from 'constants/colors';
import Box from '@mui/material/Box';

// import CustomTableFooter from './CustomTableFooter/CustomTableFooter';
import Typography from 'components/common/Typography';
import Avatar from 'components/common/Avatar';

const StyledDataGrid = styled(DataGrid)(`
.MuiDataGrid-row:nth-of-type(odd){
    background: white;
  }
  .MuiDataGrid-row:hover{
    background: ${colors.green100};
  }
.MuiDataGrid-columnHeaders{
  background: ${colors.neutral200};
}
.MuiDataGrid-columnHeaderTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.MuiDataGrid-rowSeparator{
  display: 'none',
},
`);

type Props = {
  users: any[];
  usersCount: number | null;
};

export default function UserCompanyMyUsersTable({ users, usersCount }: Props) {
  const { t } = useTranslation('common');
  const [height, setHeight] = useState(340);

  useLayoutEffect(() => {
    if (users.length <= 5) {
      setHeight(users.length * 80 + 108);
    } else {
      setHeight(440);
    }
  }, [users.length]);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: `${t('User')}`,
      width: 260,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Grid container spacing={2} direction="row" wrap="nowrap">
            <Grid item xs={'auto'}>
              <Avatar
                src={'/'}
                alt={`${params.row.first_name} ${params.row.last_name}`}
                sx={{
                  color: 'neutral.contrastText',
                  backgroundColor: colors.neutral200,
                  border: '0.1px solid onSurface.100',
                }}
              />
            </Grid>
            <Grid item xs>
              <Grid container spacing={0}>
                <Grid item>
                  <Typography variant="body1">
                    {`${params.row.first_name} ${params.row.last_name}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{params.row.email}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const rows = users.map((user, index) => {
    return {
      id: index,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
    };
  });

  // const CustomFooterComp = () => {
  //   return <CustomTableFooter usersCount={usersCount} />;
  // };

  return (
    <Box>
      {usersCount !== null && usersCount !== 0 ? (
        <div style={{ height: `${height}px` }}>
          <StyledDataGrid
            rowHeight={80}
            rows={rows}
            columns={columns}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            // slots={{
            //   pagination: CustomFooterComp,
            // }}
            style={{ borderStyle: 'none' }}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" color={colors.neutral600}>
          {t('0 results found')}
        </Typography>
      )}
    </Box>
  );
}
