import React from 'react';
import { observer } from 'mobx-react-lite';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Paper } from '@mui/material';

type Props = {
  companyName: string;
  logo?: string;
};

function CompanyName({ companyName, logo }: Props) {
  return (
    <Stack direction={'row'} spacing={3} alignItems={'center'}>
      <Paper elevation={8} sx={{ p: 1.25, borderRadius: 1.5 }}>
        <Avatar
          src={logo}
          alt=""
          sx={{
            width: 60,
            height: 60,
            '& .MuiAvatar-img': {
              objectFit: 'contain',
            },
            fontSize: '48px',
            fontWeight: 900,
            color: 'neutral.contrastText',
            backgroundColor: colors.neutral100,
            border: '0.1px solid onSurface.100',
            borderRadius: 1,
          }}
        />
      </Paper>
      <Typography variant="h3" color={colors.neutral800}>
        {companyName}
      </Typography>
    </Stack>
  );
}

export default observer(CompanyName);
