import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { CloseIcon, DownloadIcon } from 'components/icons/mui_icons';
import Loading from 'components/common/Loading';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import useUserCompanyExportFiles, {
  useUserCompanyExportFilesCreate,
} from 'hooks/useUserCompanyExportFiles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import colors from 'constants/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function DownloadCollectionData({
  collectionId,
  disabled,
}: {
  collectionId?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('common');
  const [clickedDownload, setClickDownload] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);

  const { mutateAsync, isLoading: isCreating } =
    useUserCompanyExportFilesCreate({ collectionId });

  const {
    isLoading: isLoadingExports,
    isFetching,
    refetch,
    data,
  } = useUserCompanyExportFiles({
    enabled: clickedDownload,
    collectionId,
  });

  useEffect(() => {
    if (
      !isFetching &&
      !isCreating &&
      !isLoadingExports &&
      clickedDownload &&
      data &&
      data.results
    ) {
      if (data.results[0].status === 'COMPLETED') {
        const regex = /\/([^\/]+\.xlsx)/;
        const match = data.results[0].file.match(regex);
        downloadFile(data.results[0].file, match ? match[1] : 'Export.xlsx');
        setClickDownload(false);
      } else if (data.results[0].status === 'FAILED') {
        // handle failure
        setClickDownload(false);
      }
    }
  }, [
    isLoadingExports,
    isFetching,
    isCreating,
    clickedDownload,
    data,
    setClickDownload,
  ]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.clientWidth);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportXlsx = async () => {
    handleMenuClose();
    await mutateAsync();
    refetch();
    setClickDownload(true);
  };

  return (
    <>
      <Button
        type="button"
        variant="outlined"
        color="inherit"
        onClick={handleMenuOpen}
        sx={{
          backgroundColor: colors.neutral100,
          textTransform: 'none',
          fontWeight: 'bold',
        }}
        disabled={clickedDownload || isCreating || disabled}
        endIcon={
          clickedDownload ? <Loading size="20px" /> : <KeyboardArrowDownIcon />
        }
      >
        <Box component={'span'} pr={0.5} sx={{ fontWeight: 100 }}>
          {t('Export:')}
        </Box>
        {t('All Data')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: menuWidth,
            },
            elevation: 1,
          },
        }}
      >
        <MenuItem onClick={handleExportXlsx}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <DownloadIcon />
            <Typography variant="body1">{t('Export XLSX')}</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadCollectionData;
