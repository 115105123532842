import React from 'react';
import { Tooltip, Box } from '@mui/material';
import colors from 'constants/colors';
import { CheckOutlined } from '@mui/icons-material';
import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';
import { CloseIcon } from 'components/icons/mui_icons';

export interface ChecklistItem {
  label: string;
  value?: boolean;
  progress?: boolean;
}

interface TooltipChecklistProps {
  /** The list of items to display in the tooltip */
  checklist: ChecklistItem[];
  /** The content over which the tooltip will appear (e.g. an icon, text, button) */
  children: React.ReactNode;
}

const TooltipChecklist: React.FC<TooltipChecklistProps> = ({
  checklist,
  children,
}) => {
  return (
    <Tooltip
      placement="right"
      arrow
      sx={{ width: '100%' }}
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {checklist.map(({ label, value, progress }, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={1}
              alignItems={'center'}
              minHeight={'24px'}
            >
              {value === undefined ? (
                <Box
                  sx={{
                    width: 10,
                    minWidth: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: colors.warning500,
                  }}
                />
              ) : value ? (
                <CheckOutlined
                  sx={{ color: colors.checkGreen }}
                  fontSize="small"
                />
              ) : (
                <CloseIcon
                  sx={{ color: colors.tertiary500 }}
                  fontSize="small"
                />
              )}

              <Typography sx={{ lineHeight: 1.2 }} variant="caption">
                {label}
              </Typography>
            </Stack>
          ))}
        </Box>
      }
    >
      <Box width={'100%'}>{children}</Box>
    </Tooltip>
  );
};

export default TooltipChecklist;
