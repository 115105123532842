import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';

import useAuth from 'hooks/useAuth';
import useCompanies from 'hooks/useCompanies';
import { useSearchParams } from 'react-router-dom';
import useMultiSelectFilterNavigation from 'hooks/useMultiSelectFilterNavigation';

import Box from 'components/common/Box';
import StyledTagButton from 'components/common/StyledTagButton';
import { impactCategoriesIcons } from 'components/icons/impact_categories/index';
import colors from 'constants/colors';

const ImpactCategoryFilter = () => {
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const multiSelectFilter = useMultiSelectFilterNavigation();

  const {
    loadImpactCategories,
    impactCategories,
    isLoadingImpactCategories,
    hasLoadedImpactCategories,
  } = useCompanies();
  const keys = Object.keys(impactCategories);

  React.useEffect(() => {
    if (!hasLoadedImpactCategories && !isLoadingImpactCategories) {
      loadImpactCategories({ auth });
    }
  }, [
    auth,
    loadImpactCategories,
    hasLoadedImpactCategories,
    isLoadingImpactCategories,
  ]);

  return (
    !isLoadingImpactCategories && (
      <Box
        sx={{
          borderRadius: 0,
          height: { sm: '100%' },
          overflow: 'hidden',
        }}
      >
        {Object.entries(impactCategories).map((filterObjArray, i) => {
          let readableText = `${filterObjArray[1]}`;
          //@ts-ignore
          const Component = impactCategoriesIcons[filterObjArray[0]];
          return (
            <div key={i} style={{ overflow: 'hidden' }}>
              <StyledTagButton
                key={i}
                height={'40px'}
                width={'204px'}
                title={readableText}
                justifyContent="flex-start"
                value={keys[i]}
                sx={{ mt: '2px' }}
                buttonColor={
                  searchParams.get('tcc_impact_categories')?.includes(keys[i])
                    ? 'secondary'
                    : 'white'
                }
                buttonColorHover="secondary"
                fontColor={
                  searchParams.get('tcc_impact_categories')?.includes(keys[i])
                    ? 'white'
                    : colors.neutral700
                }
                fontColorOnHover={colors.neutral700}
                onClick={(e: any) => {
                  multiSelectFilter('tcc_impact_categories', e.target.value);
                }}
              >
                {
                  <Component
                    sx={{
                      mr: '16px',
                      width: '16px',
                      height: '16px',
                      pointerEvents: 'none',
                      color: searchParams
                        .get('tcc_impact_categories')
                        ?.includes(keys[i])
                        ? 'white'
                        : 'inherit',
                    }}
                  />
                }
              </StyledTagButton>
            </div>
          );
        })}
      </Box>
    )
  );
};

export default observer(ImpactCategoryFilter);
