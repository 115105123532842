import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { lighten } from '@mui/material/styles';
import themeColors from 'constants/colors';
import appColors from 'constants/colors';
import TooltipChecklist, { ChecklistItem } from './TooltipChecklist';
import Box from '@mui/material/Box';

interface DashboardDonutChartProps {
  labels: string[];
  data: number[];
  colors: string[];
  selectedFilter: number | null;
  onFilterSelect: (index: number) => void;
  tooltipChecklistData?: ChecklistItem[][];
  displayMode: 'number' | 'percent';
}

const DashboardDonutChart: React.FC<DashboardDonutChartProps> = ({
  labels,
  data,
  colors,
  selectedFilter,
  onFilterSelect,
  tooltipChecklistData,
  displayMode = 'number',
}) => {
  // Ensure the chart is rendered only after mount to avoid SSR and DOM issues.
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);

  // Compute fill colors: if a slice is selected, keep its color; otherwise, lighten it.
  const computedColors = React.useMemo(() => {
    if (selectedFilter === null) return colors;
    return colors.map((color, index) =>
      index === selectedFilter ? color : lighten(color, 0.7)
    );
  }, [colors, selectedFilter]);

  // Compute stroke colors: selected slice uses its fill color, others use a neutral color.
  const computedStrokeColors = React.useMemo(() => {
    return colors.map((color, index) =>
      selectedFilter === index ? color : appColors.neutral200
    );
  }, [colors, selectedFilter]);

  const options: ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: false },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const index = config.dataPointIndex;
          if (typeof index === 'number') {
            // Delay the state update to let the chart's click event finish
            setTimeout(() => {
              onFilterSelect(index);
            }, 0);
          }
        },
      },
    },
    labels,
    colors: computedColors,
    stroke: {
      width: 1,
      colors: computedStrokeColors,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
      y: {
        formatter: (value: number) => value.toString(),
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '60%',
          labels: { show: false },
        },
      },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
  };

  // Calculate total for percentage calculation
  const total = data.reduce((acc, value) => acc + value, 0);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      {/* Left column: Donut chart and toggle */}
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box sx={{ width: '110px', height: '110px' }}>
          {mounted && (
            <Chart
              options={options}
              series={data}
              type="donut"
              width="110"
              height="110"
            />
          )}
        </Box>
      </Box>

      {/* Custom Legend */}
      <Stack
        direction="column"
        spacing={0}
        sx={{ ml: 2 }}
        alignItems="flex-start"
      >
        {labels.map((label, index) => {
          // Determine the display value based on the toggle mode
          const displayValue = data[index];

          const displayPercentage =
            total > 0 ? `${Math.round((data[index] / total) * 100)}%` : '0%';

          const buttonContent = (
            <>
              {label} ({displayValue}){' '}
              <Box sx={{ pl: 1, display: 'inline', opacity: 0.4 }}>
                {displayPercentage}
              </Box>
            </>
          );

          return (
            <Box key={`legend-item-${index}`}>
              {tooltipChecklistData && tooltipChecklistData[index] ? (
                <TooltipChecklist checklist={tooltipChecklistData[index]}>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() => onFilterSelect(index)}
                    sx={{
                      pointerEvents: data[index] === 0 ? 'none' : undefined,
                      textTransform: 'none',
                      textAlign: 'left',
                      pl: 1,
                      pr: 1,
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      backgroundColor:
                        selectedFilter === index
                          ? themeColors.yellow500
                          : undefined,
                      '&:hover': {
                        backgroundColor:
                          selectedFilter === index
                            ? themeColors.yellow500
                            : undefined,
                      },
                    }}
                    startIcon={
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: colors[index],
                        }}
                      />
                    }
                  >
                    {buttonContent}
                  </Button>
                </TooltipChecklist>
              ) : (
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => onFilterSelect(index)}
                  sx={{
                    pointerEvents: data[index] === 0 ? 'none' : undefined,
                    textTransform: 'none',
                    textAlign: 'left',
                    pl: 1,
                    pr: 1,
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal',
                    backgroundColor:
                      selectedFilter === index
                        ? themeColors.yellow500
                        : undefined,
                    '&:hover': {
                      backgroundColor:
                        selectedFilter === index
                          ? themeColors.yellow500
                          : undefined,
                    },
                  }}
                  startIcon={
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor: colors[index],
                      }}
                    />
                  }
                >
                  {buttonContent}
                </Button>
              )}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default DashboardDonutChart;
