import Card from 'components/common/Card';
import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Container from 'components/common/Container';
import { Link, useNavigate, useParams } from 'react-router-dom';

import CardContent from '@mui/material/CardContent';
import Avatar from 'components/common/Avatar';
import Grid from 'components/common/Grid';
import colors from 'constants/colors';
import Stack from 'components/common/Stack';
import VerifiedIcon from '@mui/icons-material/VerifiedUserOutlined';
import TccInputTextField from 'components/common/TccInputTextField';
import { useTranslation } from 'react-i18next';
import useAdminCompany, { useAdminCompanyPatch } from 'hooks/useAdminCompany';
import IconButton from 'components/common/IconButton';
import { EditIcon } from 'components/icons/mui_icons';
import EditOutlined from '@mui/icons-material/EditOutlined';
import EditCompanyDialog from './EditCompanyDialog';
import { getCountryName } from 'utils/getCountryName';
import useAdminCompanyDataPoints from 'hooks/useAdminCompanyDataPoints';
import CompanyConsolidationTable from './CompanyConsolidationTable';
import { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import UpdateIcon from '@mui/icons-material/Update';
import { setPageTitle } from 'utils/tools';
import useCompanySectors from 'hooks/useCompanySectors';
import Chip from '@mui/material/Chip';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

function CompanyDetails() {
  const boxSx = { width: 80, height: 80, ml: 2, mt: 2 };
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { data: company, refetch } = useAdminCompany(companyId);
  const { data: dps } = useAdminCompanyDataPoints({ companyId });
  const { data: cdpJobs } = useAdminCompanyJobs({
    companyId,
    status: 'COMPLETED',
    type: 'CDP',
  });
  const { data: sectors } = useCompanySectors();
  const [showConsolidation, setShowConsolidation] = useState(false);

  useEffect(() => {
    if (company?.name) {
      setPageTitle(company.name);
    }
    return () => setPageTitle();
  }, [company]);

  const cdpYears =
    cdpJobs && cdpJobs?.results
      ? cdpJobs.results.map((job: any) => {
          return job.inputs[0]?.message?.disclosure_year;
        })
      : [];

  const { t } = useTranslation();
  return company ? (
    <Stack spacing={2} maxWidth={'100%'}>
      <Box>
        <Card
          noBoxShadow
          borderColor={company.verified ? colors.blue500 : colors.warning500}
          icon={
            company.verified ? (
              <VerifiedIcon color={'secondary'} />
            ) : (
              <WarningAmberOutlinedIcon color="warning" />
            )
          }
        >
          <Stack spacing={4} direction={'row'} alignItems={'center'}>
            <Avatar sx={boxSx} src={company.logo} />

            <Stack>
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ pr: 6 }}
                alignItems={'center'}
              >
                {company.name ? (
                  <Link
                    to={`/company/${company.slug}`}
                    target="_blank"
                    style={{ textDecoration: 'none', color: colors.neutral500 }}
                  >
                    <Typography variant="h4" color={colors.neutral700}>
                      {company.name}
                    </Typography>
                  </Link>
                ) : null}
                {sectors ? (
                  <Chip
                    color={company.sector ? undefined : 'warning'}
                    label={
                      company.sector
                        ? sectors[company.sector]
                        : 'Sector missing'
                    }
                  />
                ) : null}
                <Box>
                  <IconButton
                    onClick={() => {
                      navigate(`/admin/company/${companyId}/edit`);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Stack>
              {company.country ? (
                <Typography variant="h5" color={colors.neutral600}>
                  {getCountryName(company.country)}
                </Typography>
              ) : null}
              {company.website ? (
                <Link
                  color="inherit"
                  to={
                    company.website?.startsWith('http')
                      ? company.website
                      : `https://${company.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${company.website}`}
                </Link>
              ) : null}

              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant="body1" color={colors.neutral400}>
                  {company.last_screened
                    ? `last screened ${company.last_screened}`
                    : 'never screened'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <EditCompanyDialog />
        </Card>
      </Box>
      <Box>
        <Button
          variant="text"
          startIcon={<AutoAwesomeMotionOutlinedIcon />}
          onClick={() => setShowConsolidation(!showConsolidation)}
        >
          {!showConsolidation ? t('Consolidate') : t('Hide')}
        </Button>
      </Box>
      {showConsolidation ? (
        <Box>
          <CompanyConsolidationTable />
        </Box>
      ) : null}
    </Stack>
  ) : null;
}

export default CompanyDetails;
