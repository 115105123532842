import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useForgetPasswordStore from './store/useForgetPasswordStore';

import Form from 'components/common/Form';
import UnauthenticatedContainer from 'components/common/UnauthenticatedContainer';
import Grid from 'components/common/Grid';
import Input from 'components/common/Input';
import Button from 'components/common/Button';

const ForgetPassword = () => {
  const { onForgetPasswordSubmit, isSubmitting, form, emailSent } =
    useForgetPasswordStore();
  const auth = useAuth();
  const snackbarMessage = useSnackbarMessage();
  const { t } = useTranslation('common');
  const onSubmit = () => onForgetPasswordSubmit({ auth, snackbarMessage });

  return (
    <UnauthenticatedContainer
      title={t('Reset password')}
      maxWidth="xs"
      subtitle={
        emailSent
          ? t('Please check your email.')
          : t('Enter your email to reset your password.')
      }
    >
      {!emailSent && (
        <Form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                color="secondary"
                required
                fullWidth
                id="forgetPassword-email"
                label={t('Email Address')}
                name="email"
                autoComplete="email"
                autoFocus
                error={form.errors.has('email')}
                helperText={form.errors.get('email')}
                value={form.values.get('email')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setValue('email', e.target.value)
                }
                onBlur={() => form.checkFieldError('email')}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={form.hasErrors || isSubmitting || emailSent}
              >
                {t('Submit')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </UnauthenticatedContainer>
  );
};

export default observer(ForgetPassword);
