import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import Stack from 'components/common/Stack';

import Form from 'components/common/Form';
import Typography from 'components/common/Typography';
import Grid from 'components/common/Grid';
import LinearProgress from 'components/common/LinearProgress';
import Button from 'components/common/Button/Button';
import TccCheckbox from 'components/common/TccCheckbox';
import Container from 'components/common/Container';
import AppLoading from 'components/common/AppLoading';

import LinearStepper from 'components/common/LinearStepper';

// import WizardStore from './store';
import useWizardStore from 'components/App/Routes/CrcWizard/Wizard/store/WizardStore';

//TODO: fix this to accept any success component when we need to use the wizard again
import CrcSuccess from 'components/App/Routes/CrcWizard/CrcSuccess';
import colors from 'constants/colors';
import CrcStartPage from '../CrcStartPage';

const WizardContent = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const [crcSuccess, setCrcSuccess] = React.useState(false);
  // const {  crcActive,showCrcQuestions } = useWizardStore();

  const {
    id,
    questions,
    nextGroup,
    getPreviousGroup,
    getNextGroup,
    previousGroup,
    groupCount,
    currentGroupIndex,
    submitCurrentQuestionSingleChoice,
    submitCurrentQuestionMultipleChoice,
    isCrcComplete,
    finalizeCrcSurvey,
    questionAlreadyAnswered,
    crcActive,
    showCrcQuestions,
    hasInitialized,
    isInitializing,
  } = useWizardStore();

  const isfirstCrcIncomplete =
    !auth.userDetails.has_completed_crc && !showCrcQuestions && !crcActive;

  return isfirstCrcIncomplete ? (
    <CrcStartPage />
  ) : isCrcComplete && crcSuccess ? (
    <CrcSuccess />
  ) : !crcActive ? (
    <Navigate to="/login" replace={true} />
  ) : !hasInitialized || isInitializing ? (
    <AppLoading />
  ) : (
    <Container pageContainer maxWidth="sm">
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={1}
        maxWidth={400}
      />
      <Form onSubmit={() => getNextGroup()}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center' }}
              variant="h5"
              color={colors.neutral500}
            >
              {t('Climate Readiness Check')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center' }}
              variant="body1"
              color={'neutral.100'}
            >
              {t('{{current}} of {{total}}', {
                current: groupCount > 0 ? currentGroupIndex + 1 : 0,
                total: groupCount,
              })}
            </Typography>
          </Grid>

          <>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <LinearProgress
                variant="determinate"
                value={(currentGroupIndex + 1) * 20}
              />
            </Grid>
            <Grid item xs={3} />
          </>

          {questions.map((question: any) => {
            return (
              <Grid item xs={12} key={question.id}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="h4"
                      color={colors.neutral700}
                    >
                      {question.question_text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="body1"
                      color={'text.primary'}
                    >
                      {question.question_help_text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      // Here is the question type switch:
                      question.question_type === 'SINGLE_SELECT' ? (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          sx={{ alignItems: 'center' }}
                          onChange={(e: any, value) => {
                            submitCurrentQuestionSingleChoice(
                              { auth, id },
                              question.question_identifier,
                              value
                            );
                          }}
                          value={
                            question.options
                              .filter((option: any) => option.selected === true)
                              .map(
                                (filteredOption: any) => filteredOption.text
                              )[0]
                              ? question.options
                                  .filter(
                                    (option: any) => option.selected === true
                                  )
                                  .map(
                                    (filteredOption: any) => filteredOption.text
                                  )[0]
                              : ''
                          }
                        >
                          <Stack direction={'column'}>
                            {question.options.map((option: any) => {
                              return (
                                <FormControlLabel
                                  key={option.id}
                                  value={option.text}
                                  control={<Radio color="secondary" required />}
                                  label={option.text}
                                  sx={{
                                    '.MuiFormControlLabel-asterisk': {
                                      display: 'none',
                                    },
                                  }}
                                  // checked={selectedValue} if we need only view without editing
                                />
                              );
                            })}
                          </Stack>
                        </RadioGroup>
                      ) : question.question_type === 'MULTI_SELECT' ? (
                        <Stack direction={'column'}>
                          {question.options.map((option: any) => (
                            <Stack
                              direction={'row'}
                              key={option.id}
                              justifyContent={
                                question.question_identifier === '1.2'
                                  ? 'center'
                                  : 'flex-start'
                              }
                            >
                              <FormControlLabel
                                control={
                                  <TccCheckbox
                                    color="secondary"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean
                                    ) =>
                                      submitCurrentQuestionMultipleChoice(
                                        { auth, id },
                                        question.question_identifier,
                                        option.text,
                                        checked
                                      )
                                    }
                                    sx={{
                                      paddingTop: '0px',
                                      '.MuiFormControlLabel-asterisk': {
                                        display: 'none',
                                      },
                                    }}
                                    checked={
                                      option.selected ? option.selected : false
                                    }
                                  />
                                }
                                sx={{ alignItems: 'flex-start' }}
                                label={option.text}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      ) : question.question_type === 'FREE_TEXT' ? (
                        <div>freetext</div>
                      ) : (
                        <div>type not supported</div>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Stack spacing={2}>
              {questionAlreadyAnswered && currentGroupIndex === 4 ? (
                <Button
                  fullWidth
                  sx={{ height: '40px' }}
                  variant="contained"
                  type="button"
                  size="large"
                  disabled={!questionAlreadyAnswered}
                  onClick={async () => {
                    await finalizeCrcSurvey({ auth, id });
                    await auth.loadUserData();
                    setCrcSuccess(true);
                  }}
                >
                  {t('Complete Check')}
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{ height: '40px' }}
                  variant="contained"
                  type="submit"
                  size="large"
                  disabled={!nextGroup}
                >
                  {t('Next')}
                </Button>
              )}
              {previousGroup && (
                <Button
                  fullWidth
                  sx={{ height: '40px' }}
                  variant="formBackButton"
                  type="button"
                  size="medium"
                  onClick={getPreviousGroup}
                >
                  {t('back')}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default observer(WizardContent);
