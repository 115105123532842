// CompanySelect.tsx
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import TextField from 'components/common/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from 'components/common/Typography';
import Button from 'components/common/Button';
import CircularProgress from '@mui/material/CircularProgress';
import colors from 'constants/colors';
import { useAdminCompaniesInfinite } from 'hooks/useAdminCompanies';
import useAdminCompany from 'hooks/useAdminCompany';

// ----------------------
// ListBox component with added loadMore button
// ----------------------
interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
  loadMore?: () => void; // custom property to load more items
}

const ListBox = forwardRef(function ListBoxBase(
  props: ListBoxProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const { children, loadMore, ...rest } = props;
  const innerRef = useRef<HTMLUListElement>(null);

  // FIX: Use non-null assertion (!) so that the returned value is not null.
  useImperativeHandle(ref, () => innerRef.current!);

  return (
    <ul
      {...rest}
      ref={innerRef}
      //eslint-disable-next-line
      role="list-box"
    >
      {children}
      {loadMore ? (
        // Button to load more companies if available
        <Button fullWidth onClick={loadMore}>
          Load more
        </Button>
      ) : null}
    </ul>
  );
});

// ----------------------
// Main CompanySelect component
// ----------------------
interface CompanySelectProps {
  title?: string;
  subtitle?: string;
  onChange: (companyId: string) => void;
  required?: boolean;
  value?: string; // company id
  error?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const CompanySelect = ({
  title,
  subtitle,
  onChange,
  required,
  value,
  error,
  placeholder,
  disabled,
}: CompanySelectProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  // NEW: Local state to store the selected company immediately when clicked.
  // This ensures the company name shows in the text box right away.
  const [selectedOption, setSelectedOption] = useState<any>(null);

  // Fetch the selected company details so we can always show the name.
  // (This might be loading if the company isn’t yet fetched from the API.)
  const {
    data: company,
    isLoading: companyLoading,
    isError: companyError,
  } = useAdminCompany(value);

  // Use infinite query to fetch companies based on searchText.
  const {
    data: companiesData,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isError: companiesError,
  } = useAdminCompaniesInfinite({
    enabled: open,
    search: searchText,
    pageSize: 10,
  });

  // Combine pages into one list of options.
  const options = companiesData?.pages.flatMap((page) => page.results) || [];

  // NEW: Deduplicate options by company id so that duplicate keys are not generated.
  const uniqueOptions = Array.from(
    new Map(options.map((option) => [option.id, option])).values()
  );

  // Determine the value to show in the Autocomplete.
  // Use local selectedOption if available, otherwise use the fetched company.
  const selectedValue = selectedOption || company || null;

  // NEW: Merge the selected value into the options if it is not already present.
  const finalOptions =
    selectedValue &&
    !uniqueOptions.some((option) => option.id === selectedValue.id)
      ? [selectedValue, ...uniqueOptions]
      : uniqueOptions;

  // NEW: When the external value (and fetched company) changes, update local state.
  // This ensures that if the parent passes a new value, the local state reflects it.
  useEffect(() => {
    if (value && company) {
      setSelectedOption(company);
    }
    if (!value) {
      setSelectedOption(null);
    }
  }, [value, company]);

  return (
    <Box display="flex" flexDirection="column">
      {title && (
        <Typography
          pb={subtitle ? 0 : 1}
          variant="body1"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="caption2"
          color={error ? 'error.main' : 'neutral.contrastText'}
          pb={1}
        >
          {`${subtitle}${required && !title ? ' *' : ''}`}
        </Typography>
      )}
      <Autocomplete
        open={open}
        disabled={disabled || (!!value && companyLoading)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        // NEW: Custom renderOption to assign a unique key (combining id and name)
        // This helps avoid duplicate key warnings.
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}-${option.name}`}>
            {option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option.name}
        options={finalOptions} // UPDATED: Use finalOptions which includes the selected value.
        loading={isFetching || isFetchingNextPage}
        // NEW: Use our local state value to show the company name immediately.
        value={selectedValue}
        // Disable internal filtering; the API handles searching.
        filterOptions={(x) => x}
        onChange={(e, option) => {
          // Immediately update local state so the name appears right away.
          setSelectedOption(option);
          const companyId = option?.id;
          onChange(companyId || '');
        }}
        onInputChange={(e, inputValue, reason) => {
          setSearchText(inputValue);
        }}
        ListboxComponent={ListBox}
        // FIX: Cast ListboxProps as 'any' to allow our custom 'loadMore' property.
        ListboxProps={
          {
            loadMore: hasNextPage ? fetchNextPage : undefined,
          } as any
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            placeholder={placeholder}
            sx={{
              backgroundColor: colors.neutral100,
              '& fieldset': { border: 'none' },
              disableUnderline: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ||
                  isFetchingNextPage ||
                  (!!value && companyLoading) ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {(companyError || companiesError) && (
        <Alert sx={{ mt: 1 }} severity="error">
          {t('There was a problem')}
        </Alert>
      )}
    </Box>
  );
};

export default CompanySelect;
