import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import {
  getClimateScoreAsPercent,
  getClimateScoreCategoryScores,
} from 'utils/tools';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

import Box from 'components/common/Box';
import SelectMenu from 'components/common/SelectMenu';
import { useUserCompanyCollections } from 'hooks/useUserCompanyCollections';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import Check from 'components/icons/Check';
import Download from '@mui/icons-material/Download';
import Link from 'components/common/Link';
import colors from 'constants/colors';
import { fontWeight } from 'constants/typography';
import LinearProgress from 'components/common/LinearProgress';
import { ChevronRight } from '@mui/icons-material';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import useCompanySectors from 'hooks/useCompanySectors';
import Chip from 'components/common/Chip';
import CollectionsList from './CollectionsList';
import Container from 'components/common/Container';

function UserDashboardCollections() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const {
    userCompany,
    userCompanyCPALink,
    loadUserCompany,
    hasLoadedUserCompany,
    isLoadingUserCompany,
  } = useUserCompany();
  const navigate = useNavigate();

  const { data: sectors } = useCompanySectors();

  const { isLoading, data, isError } = useUserCompanyCollections();

  useEffect(() => {
    if (!isLoadingUserCompany && !hasLoadedUserCompany) {
      loadUserCompany({ auth });
    }
  }, [auth, isLoadingUserCompany, hasLoadedUserCompany, loadUserCompany]);

  const { collectionId } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Container pageContainer>
      <Stack spacing={5}>
        <Typography variant="h4">{t('Programs')}</Typography>
        <CollectionsList />
      </Stack>
    </Container>
  );
}

export default observer(UserDashboardCollections);
