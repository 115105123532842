import { lighten, darken } from '@mui/material/styles';

const colors = {
  onSurface200: '#585757',
  onSurface100: '#1C1C1C',
  // neutral800: '#131D29',
  // neutral700: '#2D3F53',
  // neutral600: '#4E6883',
  // neutral500: '#6F89A4',
  // neutral400: '#98A8B9',
  // neutral200: '#E6EBEF',
  // neutral100: '#F9F9F9',
  warning100: '#FFF3CC',
  warning500: '#FFAB00',
  tertiary100: lighten('#ffccd8', 0.2),
  tertiary500: '#EB6E80',
  tertiary600: '#E15555',
  tertiary800: '#964652',
  white: '#FFFFFF',
  black: '#000000',
  neutralBlack: '#18181B',
  error: '#E15555',
  //
  yellow700: darken('#FBFB62', 0.05),
  yellow500: '#FBFB62',
  yellow200: lighten('#FBFB62', 0.8),
  textLight900: '#18181B',
  textLight700: lighten('#19191C', 0.7),
  textDark900: '#FFFFFF',
  textDark700: darken('#FFFFFF', 0.05),
  blue700: '#113192',
  blue500: '#2647A9',
  blue400: lighten('#2647A9', 0.2),
  blue200: '#D6E0FF',
  green700: '#295950',
  green500: '#356A5F',
  green300: '#C4E2DC',
  green200: '#C4E2DC',
  green100: '#EAF9F6',
  lilac: '#E2D9EF',
  checkGreen: '#3FD953',

  neutral800: '#27272A',
  neutral700: '#3F3F46',
  neutral600: '#52525B',
  neutral500: '#71717A',
  neutral400: '#A8A29E',
  neutral300: '#D6D6D4',
  neutral200: '#E7E5E4',
  neutral100: '#F4F2F0',
  neutral50: '#FAFAF9',

  //
  gold: {
    start: '#e0cd60',
    middle: '#c6ae3c',
    end: '#bba43f',
    text: '#825e01',
  },
  silver: {
    start: '#bcbcbc',
    middle: '#b5b5b5',
    end: '#8c8c8c',
    text: '#555555',
  },
  bronze: {
    start: '#e29f81',
    middle: '#d38360',
    end: '#9f5a3f',
    text: '#81552c',
  },
};

export default colors;
