import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from 'components/common/Chip';

import colors from 'constants/colors';

type Props = {
  isAdmin?: boolean;
  published: boolean;
  visibleIcon?: boolean;
  companySlug?: string;
  userCompanySlug?: string;
};

// for the list view we don't need to see the visible icon
// in suppliers table we need both states
// we control it by visibleIcon

function TccPublishChip({
  isAdmin = true,
  published,
  visibleIcon = true, //controls visibility of visible icon in list view for admin user
  companySlug, // we need in order to compare if the company is the same with the user's company in order to show the icon in company profile
  userCompanySlug,
}: Props) {
  const { t } = useTranslation('common');
  const [forceVissibility, setForceVissibility] = useState(false);

  useEffect(() => {
    if (
      (companySlug !== undefined ||
        '' ||
        userCompanySlug !== undefined ||
        '') &&
      companySlug === userCompanySlug
    ) {
      setForceVissibility(true);
    } else {
      setForceVissibility(false);
    }
  }, [companySlug, userCompanySlug]);

  const initialStyle = {
    color: 'white !important',
    borderRadius: '40px !important',
  };
  const visibleStyle = {
    ...initialStyle,
    backgroundColor: `${colors.green200} !important`,
  };
  const hiddenStyle = {
    ...initialStyle,
    backgroundColor: `${colors.error} !important`,
  };

  if (isAdmin || forceVissibility) {
    if (published && (visibleIcon || forceVissibility)) {
      return <Chip label={t('Visible')} sx={visibleStyle} />;
    } else if (!published) {
      return <Chip label={t('private')} sx={hiddenStyle} />;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export default TccPublishChip;
