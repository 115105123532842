// @ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from 'react-simple-maps';

import iso3To2 from './iso3To2';
import colors from 'constants/colors';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { useParams } from 'react-router-dom';
import { getCountryName } from 'utils/getCountryName';
import axios from 'axios';

function WidgetCountries({
  countries = [],
  selectedCountries = [],
}: {
  countries?: string[];
  selectedCountries?: string[];
}) {
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    axios
      .get('/features.json')
      .then((response) => {
        setGeoData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
      });
  }, []);

  if (!geoData) return null;
  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
      }}
    >
      <Sphere stroke={colors.neutral200} strokeWidth={0.5} />
      <Graticule stroke="none" strokeWidth={0} />
      <Geographies geography={geoData}>
        {({ geographies }: any) =>
          geographies.map((geo: any) => {
            const yes = countries.includes(
              iso3To2[geo.id as keyof typeof iso3To2]
            );
            const yesSelected = selectedCountries.includes(
              iso3To2[geo.id as keyof typeof iso3To2]
            );
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={
                  yesSelected
                    ? colors.brandYellow
                    : yes
                    ? colors.green200
                    : colors.neutral200
                }
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
}

export default WidgetCountries;
