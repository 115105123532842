import React from 'react';

import Grid from 'components/common/Grid';

import TextTitle from './TextTitle';
import Percent from './Percent';

type Props = {
  title: string;
  top?: string;
  right: string;
  bottom?: string;
  showCompaniesP: Boolean;
  showCompanyP: Boolean;
  score?: number;
  averageScore?: number;
};

function TItleBlock({
  title,
  top,
  bottom,
  right,
  showCompaniesP,
  showCompanyP,
  score,
  averageScore,
}: Props) {
  return (
    <Grid
      sx={{
        position: 'absolute',
        top: top,
        right: right,
        bottom: bottom,
        fontSize: '20px',
        fontWeight: 'bold',
      }}
    >
      <TextTitle title={title} />
      {showCompanyP && score !== undefined ? (
        <Percent color={'inherit'} score={score} />
      ) : null}
      {showCompaniesP && averageScore !== undefined ? (
        <Percent color={'inherit'} score={averageScore} />
      ) : null}
    </Grid>
  );
}

export default TItleBlock;
