import React from 'react';
import { styled } from '@mui/material/styles';

import Typography from 'components/common/Typography';
import Container from 'components/common/Container';
import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import Logo from 'components/icons/Logo';
import LanguageSwitch from 'components/App/Layout/LanguageSwitch';
import colors from 'constants/colors';

const UnauthenticatedContainer = ({
  title,
  showLogo = true,
  subtitle,
  children,
  ...rest
}) => {
  return (
    <Container pageContainer component="main" {...rest}>
      <Stack spacing={0} alignItems={'center'}>
        {showLogo && <Logo sx={{ fontSize: '200px', lineHeight: 0 }} />}
        <Stack
          spacing={4}
          p={2}
          sx={{
            backgroundColor: colors.neutral100,
            borderRadius: 2,
            width: '100%',
          }}
        >
          <Stack spacing={2}>
            {title && (
              <Typography
                sx={{
                  marginTop: (theme) => theme.spacing(-1),
                  textAlign: 'left',
                }}
                color="inherit"
                variant="h5"
                align="center"
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                sx={{
                  marginTop: (theme) => title && theme.spacing(3),
                  padding: '0 10%',
                }}
                variant="subtitle1"
                align="center"
              >
                {subtitle}
              </Typography>
            )}
          </Stack>
          <Box flex={1}>{children}</Box>
          <Box>
            <LanguageSwitch />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default UnauthenticatedContainer;
