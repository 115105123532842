import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useCompany(slug?: string) {
  const auth = useAuth();
  return useQuery({
    enabled: !!slug,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 5000,
    staleTime: 5000,
    queryKey: ['user-company-suppliers', { slug }],
    queryFn: async () => {
      let searchParams = new URLSearchParams();

      // searchParams.append('page', params?.page + '' || '1');
      const res = await axios.get(
        `${api.url}/companies/${slug}/`,
        Object.assign(auth.requestConfig, { params: searchParams })
      );
      return res.data;
    },
  });
}
