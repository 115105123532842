import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Loading from 'components/common/Loading';
import Container from 'components/common/Container';

import useUserCompany from 'hooks/useUserCompany';
import useAuth from 'hooks/useAuth';

const UserCompany = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const {
    loadUserCompany,
    isLoadingUserCompany,
    hasLoadedUserCompany,
    userCompany,
  } = useUserCompany();

  useEffect(() => {
    if (!hasLoadedUserCompany && !isLoadingUserCompany) {
      loadUserCompany({ auth });
    }
  }, [loadUserCompany, auth, hasLoadedUserCompany, isLoadingUserCompany]);

  return (
    <Container pageContainer maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('Company Data and Settings')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  textDecoration: 'none',
                  backgroundColor: 'background.paper',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/user/company/settings/edit')}
              >
                <Typography
                  variant="subtitle1"
                  color={
                    location.pathname === '/user/company/settings/edit'
                      ? 'inherit'
                      : 'secondary'
                  }
                  textOverflow="ellipsis"
                >
                  {t('Company Information')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  textDecoration: 'none',
                  backgroundColor: 'background.paper',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/user/company/settings/data')}
              >
                <Typography
                  variant="subtitle1"
                  color={
                    location.pathname === '/user/company/settings/data'
                      ? 'inherit'
                      : 'secondary'
                  }
                  noWrap
                  textOverflow="ellipsis"
                >
                  {t('Emissions and Intensity')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  textDecoration: 'none',
                  backgroundColor: 'background.paper',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/user/company/settings/my-users')}
              >
                <Typography
                  variant="subtitle1"
                  color={
                    location.pathname === '/user/company/settings/my-users'
                      ? 'inherit'
                      : 'secondary'
                  }
                  noWrap
                  textOverflow="ellipsis"
                >
                  {t('Company Users')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  textDecoration: 'none',
                  backgroundColor: 'background.paper',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate('/user/company/settings/access-control')
                }
              >
                <Typography
                  variant="subtitle1"
                  color={
                    location.pathname ===
                    '/user/company/settings/access-control'
                      ? 'inherit'
                      : 'secondary'
                  }
                  noWrap
                  textOverflow="ellipsis"
                >
                  {t('Access Control')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          {!hasLoadedUserCompany || isLoadingUserCompany ? (
            <Loading color="primary" />
          ) : userCompany && userCompany.id ? (
            <Outlet />
          ) : (
            <Alert severity="error">user has no company assigned</Alert>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(UserCompany);
