import React from 'react';
import MuiCard from '@mui/material/Card';
import { Box, CardProps, Typography } from '@mui/material';

type cardProps = {
  primary?: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  icon?: React.ReactNode;
  noBoxShadow?: boolean;
};

function Card({
  primary = false,
  title,
  subtitle,
  color,
  backgroundColor,
  borderColor,
  children,
  icon,
  noBoxShadow,
  sx,
}: cardProps & CardProps) {
  return (
    <MuiCard
      sx={Object.assign(
        {},
        {
          position: 'relative',
          color: color,
          backgroundColor: backgroundColor,
          padding: '22px',
          border: borderColor ? `1px solid ${borderColor}` : undefined,
          boxShadow: noBoxShadow
            ? 'none'
            : '0px 8px 4px -3px rgba(230, 235, 239, 0.25), 0px 16px 32px -3px rgba(230, 235, 239, 0.85)',
        },
        sx ? sx : {}
      )}
    >
      {title ? <Typography variant="h5">{title}</Typography> : null}
      {subtitle ? (
        <Typography variant="subtitle1">{subtitle}</Typography>
      ) : null}
      <Box pt={title || subtitle ? 2 : 0}>{children}</Box>
      {icon ? (
        <Box position={'absolute'} right={'16px'} top={'16px'}>
          {icon}
        </Box>
      ) : null}
    </MuiCard>
  );
}

export default Card;
