import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import KeyFactsCard from 'components/common/CompanyKeyFacts/KeyFactsCard/KeyFactsCard';
import Button from 'components/common/Button/Button';
import { imageSrcMap } from 'media/crc_start_icon/index';
import { InfoOutlinedIcon } from 'components/icons/mui_icons';
import Container from 'components/common/Container';

import LinearStepper from 'components/common/LinearStepper';

import useAuth from 'hooks/useAuth';
import useSignUp from 'hooks/useSignUp';

const SignupSuccess = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  let navigate = useNavigate();
  const { hasSubmitted, resendEmail, hasResentEmail, signUpForm1 } =
    useSignUp(); //resendEmail, hasResentEmail,

  React.useEffect(() => {
    if (
      !hasSubmitted &&
      !auth.isAuthenticated &&
      signUpForm1.values.get('email').length === 0
    ) {
      navigate(`/signup`);
    }
  }, [auth, auth.isAuthenticated, hasSubmitted, navigate, signUpForm1.values]);

  return (
    <Container maxWidth="md" pageContainer>
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={1}
        maxWidth={400}
      />

      <Grid container justifyContent="center" alignItems="center" padding={4}>
        <Typography variant={'h4'} color={'black'}>
          {t(`You’re almost there`)}
        </Typography>
        <Box maxWidth={'sm'} mt={2} justifyContent={'center'}>
          <KeyFactsCard
            icon={<InfoOutlinedIcon />}
            text={t(
              `We’ve sent you an email to {{email}}. Please follow the instructions within to verify your email address in order to continue.`,
              {
                email: signUpForm1.values.get('email'),
              }
            )}
            width={'100%'}
          />
          <Typography
            variant={'body1'}
            color={'neutral.contrastText'}
            align={'center'}
            mb={2}
          >
            {t(
              'The Climate Readiness Check is a short set of questions that enable your organization to understand its climate maturity with respect to internationally recognised standards and objectives. Using your answers, we create a report that can provide you with an analysis of your strengths and opportunities for improvement, along with actionable recommendations for next steps.'
            )}
          </Typography>
          <Button
            fullWidth
            sx={{ height: '40px' }}
            variant="contained"
            type="button"
            size="medium"
            onClick={() =>
              resendEmail({
                auth: auth,
                email: signUpForm1.values.get('email'),
              })
            }
            disabled={hasResentEmail}
          >
            {t('Resend Verification Email')}
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};

export default observer(SignupSuccess);
