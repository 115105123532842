import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import colors from 'constants/colors';

const TotalRadar = ({
  emissionsCalculation = 0,
  strategyAndControl = 0,
  targets = 0,
  decarbonizationMeasures = 0,
  emissionsPerformance = 0,
  averageImpact = 0,
  averageLeadership = 0,
  averagePerformance = 0,
  averageTransparency = 0,
  averageActions = 0,
  width = 490,
  height = 320,
}) => {
  const { t } = useTranslation('common');
  const config = {
    series: [
      {
        name: 'Company Score',
        data: [
          emissionsCalculation,
          targets,
          strategyAndControl,
          decarbonizationMeasures,
          emissionsPerformance,
        ],
      },
    ],
    options: {
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
        },
      },
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
        toolbar: {
          show: false,
          fontFamily: 'Lato',
        },
      },
      stroke: {
        width: 4,
      },
      fill: {
        opacity: 0.1,
      },
      colors: [colors.green200, colors.blue500],
      markers: {
        size: 5,
      },
      legend: {
        show: true,
        fontFamily: ['Lato', 'sans-serif'].join(','),
        offsetX: 0,
        offsetY: 5,
      },
      xaxis: {
        categories: [
          [t('Emissons Calculation')],
          [t('Climate Targets')],
          [t('Strategy & Control')],
          [t('Decarbonization Measures')],
          [t('Emissions Performance')],
        ],
        labels: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            colors: ['#113192', '#113192', '#113192', '#113192', '#113192'],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <ReactApexChart
      options={config.options}
      series={config.series}
      type="radar"
      height={height}
      width={width}
    />
  );
};

export default TotalRadar;
