import colors from 'constants/colors';
import { PaletteOptions } from '@mui/material';

const tccPaletteDark = {
  mode: 'dark',
  primary: {
    main: colors.green300,
    hover: colors.green200,
    hoverContained: colors.green300,
    contrastText: colors.neutral100,
  },
  secondary: {
    main: colors.blue500,
  },
  neutral: {
    main: colors.neutral100,
    hover: colors.neutral200,
    contrastText: colors.neutral500,
  },
  onSurface: {
    100: colors.onSurface100,
  },
  info: {
    main: colors.neutral600,
  },
  background: {
    paper: colors.neutral600,
    default: colors.neutral800,
  },
} as PaletteOptions;

export default tccPaletteDark;
