import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import useAuth from 'hooks/useAuth';

import Box from 'components/common/Box';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import Chip from 'components/common/Chip';
import Typography from 'components/common/Typography';
import Tooltip from 'components/common/Tooltip';
import Menu from 'components/common/Menu';
import MenuItem from 'components/common/MenuItem';

import colors from 'constants/colors';

function AccountMenu() {
  const { t } = useTranslation('common');
  const navigateSearch = useNavigateSearch();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Open settings">
          <div>
            <IconButton onClick={handleClick} sx={{ p: 0 }}>
              <Avatar
                sx={{ bgcolor: colors.neutral200, color: colors.onSurface200 }}
                alt={auth.userFirstName}
                src="empty/image"
              />
            </IconButton>
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        PaperProps={{ sx: { borderRadius: '8px' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {auth.hasAdminPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/admin/company',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography component="span">{t('Data Services')}</Typography>
            <Chip sx={{ ml: 1 }} size="small" label="Admin" />
          </MenuItem>
        ) : null}
        {auth.hasAdminPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/admin/merge',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography component="span">{t('Merge')}</Typography>
            <Chip sx={{ ml: 1 }} size="small" label="Admin" />
          </MenuItem>
        ) : null}
        {auth.hasAdminPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/admin/cpa-surveys',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography component="span">{t('Assessment')}</Typography>
            <Chip sx={{ ml: 1 }} size="small" label="Admin" />
          </MenuItem>
        ) : null}
        {auth.hasAdminPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/admin/status-label',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography component="span">{'Status Label'}</Typography>
            <Chip sx={{ ml: 1 }} size="small" label="Admin" />
          </MenuItem>
        ) : null}
        {auth.hasUserPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/user/preferences',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography textAlign="center">{t('User Settings')}</Typography>
          </MenuItem>
        ) : null}
        {auth.hasUserPermission ? (
          <MenuItem
            onClick={() => {
              navigateSearch({
                pathname: '/user/company/settings',
                params: {},
                replace: true,
              });
            }}
          >
            <Typography textAlign="center">{t('Company Settings')}</Typography>
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            navigateSearch({
              pathname: '/logout',
              params: {},
              replace: true,
            });
          }}
        >
          <Typography textAlign="center">{t('Logout')}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default observer(AccountMenu);
