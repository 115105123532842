import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Logo = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox={'0 0 695 201'}
      fontSize={'inherit'}
      color="inherit"
      style={{ lineHeight: 0 }}
    >
      <path
        d="M507.109 162.627C507.109 156.074 508.443 150.358 511.11 145.481C513.777 140.528 517.473 136.718 522.197 134.051C526.922 131.308 532.332 129.936 538.429 129.936C546.278 129.936 552.755 131.917 557.86 135.88C563.042 139.766 566.509 145.253 568.262 152.34L551.524 155.064L551.002 152.34C550.088 149.596 548.526 147.463 546.316 145.939C544.182 144.338 541.515 143.538 538.314 143.538C533.742 143.538 530.123 145.215 527.455 148.568C524.788 151.844 523.455 156.531 523.455 162.627C523.455 168.647 524.788 173.334 527.455 176.687C530.123 179.963 533.742 181.602 538.314 181.602C544.792 181.602 549.021 178.706 551.002 172.915L568.427 169.573C568.427 169.573 568.586 171.487 568.262 172.915C566.509 179.773 563.042 185.221 557.86 189.26C552.679 193.299 546.201 195.318 538.429 195.318C532.332 195.318 526.922 193.985 522.197 191.318C517.473 188.574 513.777 184.764 511.11 179.887C508.443 174.934 507.109 169.181 507.109 162.627Z"
        fill="inherit"
      />
      <path
        d="M487.527 123.421C484.707 123.421 482.345 122.544 480.44 120.792C478.611 118.963 477.697 116.715 477.697 114.048C477.697 111.381 478.611 109.171 480.44 107.418C482.345 105.589 484.707 104.675 487.527 104.675C490.346 104.675 492.67 105.589 494.499 107.418C496.404 109.171 497.357 111.381 497.357 114.048C497.357 116.715 496.404 118.963 494.499 120.792C492.67 122.544 490.346 123.421 487.527 123.421ZM495.414 130.965V194.29H479.411V130.965H495.414Z"
        fill="inherit"
      />
      <path
        d="M434.638 195.318C428.542 195.318 423.056 193.985 418.179 191.318C413.302 188.574 409.453 184.726 406.634 179.773C403.891 174.82 402.519 169.104 402.519 162.627C402.519 156.15 403.929 150.435 406.748 145.481C409.644 140.528 413.568 136.718 418.521 134.051C423.475 131.308 428.999 129.936 435.096 129.936C441.192 129.936 446.717 131.308 451.67 134.051C456.623 136.718 460.509 140.528 463.329 145.481C466.225 150.435 467.673 156.15 467.673 162.627C467.673 169.104 466.187 174.82 463.215 179.773C460.319 184.726 456.356 188.574 451.327 191.318C446.374 193.985 440.811 195.318 434.638 195.318ZM434.638 181.373C437.534 181.373 440.239 180.687 442.754 179.316C445.345 177.868 447.402 175.734 448.927 172.915C450.451 170.095 451.213 166.666 451.213 162.627C451.213 156.607 449.612 151.997 446.412 148.796C443.287 145.52 439.439 143.881 434.867 143.881C430.295 143.881 426.447 145.52 423.322 148.796C420.274 151.997 418.75 156.607 418.75 162.627C418.75 168.647 420.236 173.296 423.208 176.572C426.256 179.773 430.066 181.373 434.638 181.373Z"
        fill="inherit"
      />
      <path
        d="M366.612 130.761C371.413 130.761 375.68 131.828 379.414 133.962C383.148 136.019 386.044 139.106 388.101 143.221C390.235 147.259 391.302 152.136 391.302 157.852V195.001H375.299V160.023C375.299 154.994 374.042 151.146 371.527 148.479C369.012 145.735 365.583 144.364 361.24 144.364C356.82 144.364 353.314 145.735 350.724 148.479C348.209 151.146 346.952 154.994 346.952 160.023V195.001H330.949V110.415H346.952V139.563C349.009 136.82 351.752 134.686 355.181 133.162C358.611 131.562 362.421 130.761 366.612 130.761Z"
        fill="inherit"
      />
      <path
        d="M695 57.9284C695 60.2145 694.848 62.2719 694.543 64.1008H648.25C648.631 68.673 650.231 72.2546 653.05 74.8455C655.87 77.4364 659.337 78.7318 663.452 78.7318C669.396 78.7318 673.625 76.179 676.14 71.0734H693.4C691.571 77.1696 688.066 82.1991 682.884 86.1616C677.702 90.048 671.339 91.9912 663.795 91.9912C657.699 91.9912 652.212 90.6576 647.335 87.9905C642.534 85.2472 638.762 81.3989 636.019 76.4457C633.352 71.4925 632.018 65.7773 632.018 59.3C632.018 52.7466 633.352 46.9932 636.019 42.04C638.686 37.0868 642.42 33.2767 647.221 30.6096C652.022 27.9425 657.546 26.6089 663.795 26.6089C669.815 26.6089 675.187 27.9043 679.912 30.4953C684.713 33.0862 688.409 36.782 691 41.5828C693.667 46.3074 695 51.7559 695 57.9284ZM678.426 53.3562C678.35 49.2412 676.864 45.9645 673.968 43.526C671.072 41.0113 667.529 39.7539 663.338 39.7539C659.375 39.7539 656.022 40.9732 653.279 43.4117C650.612 45.774 648.974 49.0888 648.364 53.3562H678.426Z"
        fill="inherit"
      />
      <path
        d="M636.02 162.338C636.02 164.624 635.868 166.682 635.563 168.511H589.27C589.651 173.083 591.251 176.664 594.071 179.255C596.89 181.846 600.357 183.142 604.472 183.142C610.416 183.142 614.645 180.589 617.16 175.483H634.42C632.591 181.579 629.086 186.609 623.904 190.571C618.722 194.458 612.359 196.401 604.815 196.401C598.719 196.401 593.232 195.067 588.355 192.4C583.554 189.657 579.782 185.809 577.039 180.855C574.372 175.902 573.038 170.187 573.038 163.71C573.038 157.156 574.372 151.403 577.039 146.45C579.706 141.497 583.44 137.686 588.241 135.019C593.042 132.352 598.567 131.019 604.815 131.019C610.835 131.019 616.208 132.314 620.932 134.905C625.733 137.496 629.429 141.192 632.02 145.993C634.687 150.717 636.02 156.166 636.02 162.338ZM619.446 157.766C619.37 153.651 617.884 150.374 614.988 147.936C612.093 145.421 608.549 144.164 604.358 144.164C600.395 144.164 597.042 145.383 594.299 147.821C591.632 150.184 589.994 153.499 589.384 157.766H619.446Z"
        fill="inherit"
      />
      <path
        d="M610.45 40.7836V71.4173C610.45 73.551 610.945 75.1131 611.936 76.1038C613.003 77.0182 614.756 77.4754 617.194 77.4754H624.624V90.9634H614.565C601.077 90.9634 594.333 84.4099 594.333 71.303V40.7836H586.789V27.6386H594.333V11.9789H610.45V27.6386H624.624V40.7836H610.45Z"
        fill="inherit"
      />
      <path
        d="M510.235 59.0714C510.235 52.6704 511.492 46.9932 514.007 42.04C516.598 37.0868 520.065 33.2767 524.409 30.6096C528.828 27.9425 533.743 26.6089 539.154 26.6089C543.878 26.6089 547.993 27.5614 551.499 29.4665C555.08 31.3716 557.938 33.772 560.072 36.6677V27.6376H576.189V90.9624H560.072V81.7037C558.014 84.6757 555.156 87.1523 551.499 89.1335C547.917 91.0386 543.764 91.9912 539.04 91.9912C533.705 91.9912 528.828 90.6195 524.409 87.8762C520.065 85.1329 516.598 81.2846 514.007 76.3314C511.492 71.302 510.235 65.5487 510.235 59.0714ZM560.072 59.3C560.072 55.4137 559.31 52.0988 557.786 49.3555C556.261 46.536 554.204 44.4023 551.613 42.9545C549.022 41.4304 546.241 40.6684 543.269 40.6684C540.297 40.6684 537.554 41.3923 535.039 42.8402C532.524 44.288 530.467 46.4217 528.866 49.2412C527.342 51.9845 526.58 55.2613 526.58 59.0714C526.58 62.8816 527.342 66.2345 528.866 69.1302C530.467 71.9497 532.524 74.1215 535.039 75.6456C537.63 77.1697 540.373 77.9317 543.269 77.9317C546.241 77.9317 549.022 77.2078 551.613 75.7599C554.204 74.2358 556.261 72.1021 557.786 69.3588C559.31 66.5393 560.072 63.1864 560.072 59.3Z"
        fill="inherit"
      />
      <path
        d="M473.217 27.1794C480.99 27.1794 487.238 29.5798 491.963 34.3806C496.764 39.1052 499.164 45.7349 499.164 54.2697V91.4187H483.161V56.4414C483.161 51.4882 481.904 47.7162 479.389 45.1253C476.875 42.4582 473.446 41.1246 469.102 41.1246C464.758 41.1246 461.291 42.4582 458.7 45.1253C456.186 47.7162 454.928 51.4882 454.928 56.4414V91.4187H438.926V56.4414C438.926 51.4882 437.668 47.7162 435.154 45.1253C432.639 42.4582 429.21 41.1246 424.866 41.1246C420.446 41.1246 416.941 42.4582 414.35 45.1253C411.835 47.7162 410.578 51.4882 410.578 56.4414V91.4187H394.575V28.0939H410.578V35.7523C412.635 33.0852 415.264 30.9896 418.465 29.4655C421.742 27.9415 425.323 27.1794 429.21 27.1794C434.163 27.1794 438.583 28.2463 442.469 30.38C446.355 32.4375 449.365 35.4094 451.499 39.2957C453.557 35.638 456.528 32.7042 460.415 30.4943C464.377 28.2844 468.645 27.1794 473.217 27.1794Z"
        fill="inherit"
      />
      <path
        d="M370.878 20.0936C368.058 20.0936 365.696 19.2173 363.791 17.4646C361.962 15.6357 361.047 13.3877 361.047 10.7206C361.047 8.05353 361.962 5.84364 363.791 4.09097C365.696 2.26209 368.058 1.34766 370.878 1.34766C373.697 1.34766 376.021 2.26209 377.85 4.09097C379.755 5.84364 380.708 8.05353 380.708 10.7206C380.708 13.3877 379.755 15.6357 377.85 17.4646C376.021 19.2173 373.697 20.0936 370.878 20.0936ZM378.765 27.6377V90.9625H362.762V27.6377H378.765Z"
        fill="inherit"
      />
      <path
        d="M346.952 6.37673V90.9622H330.949V6.37673H346.952Z"
        fill="inherit"
      />
      <path
        d="M243.706 130.824C240.031 137.294 238.194 144.642 238.194 152.87C238.194 161.097 240.031 168.445 243.706 174.915C247.46 181.385 252.532 186.457 258.922 190.132C265.392 193.726 272.621 195.523 280.608 195.523C290.034 195.523 298.221 193.167 305.17 188.454C312.199 183.662 317.111 176.992 319.907 168.445C320.766 165.818 321.332 163.066 321.419 159.526L315.524 161.063L306.396 163.777L306.199 163.828C304.656 164.227 303.134 164.667 301.636 165.145C301.561 165.394 301.488 165.651 301.411 165.919C301.198 166.665 300.961 167.493 300.617 168.445C298.7 172.439 295.984 175.474 292.47 177.551C289.035 179.548 285.041 180.547 280.488 180.547C275.616 180.547 271.263 179.428 267.429 177.192C263.675 174.875 260.719 171.64 258.563 167.487C256.486 163.253 255.447 158.381 255.447 152.87C255.447 147.278 256.486 142.406 258.563 138.252C260.719 134.099 263.675 130.904 267.429 128.667C271.263 126.351 275.616 125.193 280.488 125.193C285.041 125.193 289.035 126.191 292.47 128.188C295.984 130.185 298.7 133.18 300.617 137.174C300.948 138.036 301.27 138.782 301.571 139.478C301.842 140.104 302.096 140.691 302.323 141.286L309.419 139.176L319.679 136.5C316.848 128.355 312.011 121.95 305.17 117.285C298.141 112.493 289.954 110.096 280.608 110.096C272.621 110.096 265.392 111.933 258.922 115.608C252.532 119.202 247.46 124.274 243.706 130.824Z"
        fill="inherit"
      />
      <path
        d="M244.662 26.859C240.988 33.3289 239.151 40.6775 239.151 48.9047C239.151 57.1319 240.988 64.4804 244.662 70.9503C248.417 77.4202 253.489 82.4923 259.879 86.1666C266.349 89.761 273.577 91.5582 281.565 91.5582C290.99 91.5582 299.178 89.2019 306.127 84.4892C313.156 79.6967 318.068 73.0271 320.864 64.4804C321.723 61.8533 322.289 59.1008 322.375 55.5607L316.481 57.098L307.353 59.8119L307.156 59.8628C305.612 60.2621 304.091 60.7018 302.593 61.1803C302.518 61.4289 302.445 61.6861 302.368 61.9538C302.155 62.7 301.918 63.5282 301.574 64.4804C299.657 68.4742 296.941 71.5094 293.427 73.5862C289.992 75.5831 285.998 76.5815 281.445 76.5815C276.573 76.5815 272.22 75.4633 268.386 73.2268C264.631 70.9104 261.676 67.6754 259.519 63.5219C257.443 59.2885 256.404 54.4161 256.404 48.9047C256.404 43.3134 257.443 38.441 259.519 34.2874C261.676 30.1339 264.631 26.9389 268.386 24.7024C272.22 22.386 276.573 21.2278 281.445 21.2278C285.998 21.2278 289.992 22.2262 293.427 24.2231C296.941 26.22 299.657 29.2154 301.574 33.2091C301.904 34.0709 302.227 34.8165 302.528 35.5128C302.799 36.1393 303.053 36.7258 303.28 37.3208L310.375 35.2112L320.635 32.5352C317.804 24.3898 312.968 17.9848 306.127 13.3201C299.098 8.52759 290.91 6.13132 281.565 6.13132C273.577 6.13132 266.349 7.96846 259.879 11.6427C253.489 15.2371 248.417 20.3092 244.662 26.859Z"
        fill="inherit"
      />
      <path
        d="M24.2836 119.778C13.4546 77.9251 38.6041 35.2181 80.4568 24.3891C121.334 13.8125 163.025 37.5564 175.036 77.6623C181.408 74.3911 188.204 71.7207 195.368 69.7424C179.374 19.4938 126.68 -10.0513 75.0051 3.31918C21.5159 17.1591 -10.6262 71.7402 3.21371 125.229C16.6469 177.147 68.4617 208.953 120.406 198.122C118.608 190.908 117.594 183.678 117.311 176.524C76.2252 185.828 34.9091 160.844 24.2836 119.778Z"
        fill="inherit"
      />
      <path
        d="M140.429 167.26C139.951 175.004 140.555 182.933 142.355 190.851C180.047 173.325 203.089 133.919 199.822 92.4317C192.044 94.7539 184.842 98.1253 178.331 102.349C177.602 128.966 163.231 153.555 140.429 167.26Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};

export default Logo;
