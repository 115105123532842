import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';

import Box from 'components/common/Box';

import colors from 'constants/colors';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fade } from '@mui/material';

function CollectionWidgetCard({
  title,
  caption,
  component,
  small = false,
  disabled = false,
  filtered = false,
  minWidth,
}: {
  title?: string;
  caption?: any;
  component?: any;
  small?: boolean;
  disabled?: boolean;
  filtered?: boolean;
  minWidth?: string;
}) {
  return (
    <Box
      sx={{
        opacity: disabled ? 0.2 : 1,
        pointerEvents: disabled ? 'none' : undefined,
        padding: 2,
        height: small ? '130px' : '230px',
        backgroundColor: colors.neutral100,
        borderRadius: '16px',
        flex: 1,
      }}
    >
      <Fade in>
        <div>
          <Stack
            spacing={1}
            height={small ? '98px' : '198px'}
            minWidth={minWidth}
          >
            <Stack flex={1} spacing={2} direction={'row'}>
              {title && (
                <Box flex={0}>
                  <Typography
                    variant={'h3'}
                    sx={{ fontWeight: 100, color: colors.onSurface200 }}
                  >
                    {title}
                  </Typography>
                </Box>
              )}
              {component && <Box flex={1}>{component}</Box>}
            </Stack>

            {caption && typeof caption === 'string' ? (
              <Stack flex={0}>
                <Typography
                  variant={'body1'}
                  sx={{
                    fontWeight: 100,
                    color: colors.onSurface200,
                    textTransform: 'none',
                    textAlign: 'left',
                  }}
                >
                  {caption}
                </Typography>
              </Stack>
            ) : caption ? (
              <Stack flex={0}>{caption}</Stack>
            ) : null}
          </Stack>
        </div>
      </Fade>
    </Box>
  );
}

export default CollectionWidgetCard;
