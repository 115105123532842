import React from 'react';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import RadialBar from 'components/common/RadialBar/RadialBar';
import colors from 'constants/colors';
import Box from 'components/common/Box';
import CircleIcon from '@mui/icons-material/Circle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

type Props = {
  title: string;
  info: string;
  score: number;
  icon: React.ReactNode;
  breakdown?: { question: string; score: number }[];
};

function ScoreRadialSmall({ title, info, score, icon, breakdown }: Props) {
  return (
    <Stack direction={'row'}>
      <RadialBar
        sizeW={200}
        sizeH={200}
        lineSize="55%"
        score={score}
        radialOffsetY={-10}
        radialOffsetX={-12}
        fontSize={'20px'}
      />
      <Stack width={'100%'} spacing={2}>
        <Stack direction={'row'} alignItems={'center'} width={'100%'}>
          <Typography flexGrow={1} variant="h5" color="black" fontWeight={700}>
            {title}
          </Typography>
          <Box flexGrow={0}>{icon}</Box>
        </Stack>

        <Typography variant="body1" color={colors.neutral700} marginTop={'6px'}>
          {info}
        </Typography>
        <Box />
        {breakdown ? (
          <>
            <Stack spacing={1.5}>
              {breakdown.map((q, i) => {
                return (
                  <Stack key={i} spacing={2} direction={'row'}>
                    {q.score === 0 ? (
                      <CancelOutlinedIcon color="error" />
                    ) : q.score >= 1 ? (
                      <CheckCircleOutlinedIcon
                        sx={{ color: colors.checkGreen }}
                      />
                    ) : (
                      <Box
                        display={'flex'}
                        width={'24px'}
                        height={'24px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <CircleIcon
                          sx={{
                            color: colors.warning100,
                            height: '16px',
                            width: '16px',
                          }}
                        />
                      </Box>
                    )}
                    <Typography variant="body1">{q.question}</Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Box />
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default ScoreRadialSmall;
