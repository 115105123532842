import countriesI18n from 'i18n-iso-countries';
import { getLanguagePreference } from 'utils/browser';

export const getCountryName = (code: string) => {
  return (
    countriesI18n.getName(
      code,
      getLanguagePreference({ twoLetter: true, supportedLang: true })
    ) || ''
  );
};
