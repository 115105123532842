import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import CardMedia from 'components/common/CardMedia';
import Button from 'components/common/Button/Button';
import { imageSrcMap } from 'media/crc_start_icon/index';
import Container from 'components/common/Container';

import LinearStepper from 'components/common/LinearStepper';

const CrcSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Container maxWidth="sm" pageContainer>
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={2}
        maxWidth={400}
      />
      <Grid container justifyContent="center" alignItems="center" padding={4}>
        <Typography variant={'h4'} color={'black'} align="center">
          {t(`Congratulations!`)}
          <br />
        </Typography>
        <Box maxWidth={'sm'} mt={2} mb={2} justifyContent={'center'}>
          <Typography
            variant={'body1'}
            color={'neutral.contrastText'}
            align={'center'}
            mb={2}
          >
            {t(`You have completed the Climate Readiness Check.`)}
            <br />
            {t(
              `You have now access to our platform and are able to view your Climate Readiness report.`
            )}
          </Typography>
        </Box>
        <Button
          fullWidth
          sx={{ height: '40px' }}
          variant="contained"
          type="button"
          size="large"
          onClick={() => {
            navigate('/dashboard/my-company/insights', {
              state: { firstCRCView: true },
            });
          }}
        >
          {t('View the Climate Readiness report')}
        </Button>
      </Grid>
    </Container>
  );
};

export default observer(CrcSuccess);
