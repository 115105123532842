import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ClimateLeadership,
  ClimateImpactModel,
  ClimatePerfomance,
  ClimateTransparency,
} from 'components/icons/crc/crc_icons/index';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import RadialBar from 'components/common/RadialBar/RadialBar';
import ScoreRadialSmall from '../RadialBar/ScoreRadialSmall';
import colors from 'constants/colors';
import Stack from 'components/common/Stack';
import { getClimateScoreAsPercent } from 'utils/tools';
import Box from 'components/common/Box';

type CompanyCRCScoreProps = {
  survey?: {
    badge?: string;
    submitted?: string;
    id?: string;
    label?: string;
    score_climate_actions?: number;
    score_climate_impact?: number;
    score_climate_leadership?: number;
    score_climate_performance?: number;
    score_climate_transparency?: number;
    score_total_crc?: number;
  };
};

function ClimateReadinessCheck({
  overallScore,
  emissionsCalculation,
  strategyAndControl,
  targets,
  emissionsPerformance,
}: {
  overallScore?: number;
  emissionsCalculation?: number;
  strategyAndControl?: number;
  targets?: number;
  emissionsPerformance?: number;
}) {
  const { t } = useTranslation('common');

  return (
    <Stack direction="row" spacing={2}>
      {overallScore || overallScore === 0 ? (
        <RadialBar
          outOf100
          score={getClimateScoreAsPercent(overallScore)}
          offsetY={12}
          radialOffsetX={-2}
          radialOffsetY={-5}
        />
      ) : null}
      <Stack spacing={4} pt={2}>
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={4}>
          {emissionsCalculation || emissionsCalculation === 0 ? (
            <Box flex={1}>
              <ScoreRadialSmall
                title={t('Emissions Calculation')}
                info={t(
                  'The Emissions Calculation category reflects the availability and quality of climate-related emissions data at a company and product-level.'
                )}
                score={emissionsCalculation}
                icon={<ClimateImpactModel color={colors.blue500} />}
              />
            </Box>
          ) : null}

          {strategyAndControl || strategyAndControl === 0 ? (
            <Box flex={1}>
              <ScoreRadialSmall
                title={t('Strategy & Control')}
                info={t(
                  'The Strategy & Control category reflects the strategic alignment and management oversight needed to steer a company’s Climate transition successfully.'
                )}
                score={strategyAndControl}
                icon={<ClimateLeadership color={colors.blue500} />}
              />
            </Box>
          ) : null}
        </Stack>
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={4}>
          {targets || targets === 0 ? (
            <Box flex={1}>
              <ScoreRadialSmall
                title={t('Climate Targets')}
                info={t(
                  'The Climate Targets category reflects the presence and quality of specific climate targets, both long- and near-term. '
                )}
                score={targets}
                icon={<ClimatePerfomance color={colors.blue500} />}
              />
            </Box>
          ) : null}
          {emissionsPerformance || emissionsPerformance === 0 ? (
            <Box flex={1}>
              <ScoreRadialSmall
                title={t('Emissions Performance')}
                info={t(
                  'The Emissions Performance category reflects the draw-down of emissions over time.'
                )}
                score={emissionsPerformance}
                icon={<ClimateTransparency color={colors.blue500} />}
              />
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default observer(ClimateReadinessCheck);
