import React from 'react';

import Link from 'components/common/Link';
import Box from 'components/common/Box';
import colors from 'constants/colors';

type Props = {
  initialText: string;
  links: string[];
  blankPage?: boolean;
  color?: string;
  linkColor?: string;
  linkOnClick?: () => void;
};

const TextWithLinks = ({
  initialText,
  links,
  blankPage,
  color,
  linkColor,
  linkOnClick,
}: Props) => {
  const hasLink = initialText.includes('{{link_start}}');
  let linksTexts: string[] = [];
  //First Link
  const findLinkText = (text: string) => {
    const startPosition =
      text.indexOf('{{link_start}}') + '{{link_start}}'.length;
    const endPosition = text.indexOf('{{link_end}}');

    return text.substring(startPosition, endPosition);
  };
  const createSubStringsArray = (text: string) => {
    const linkString = findLinkText(text);
    linksTexts.push(linkString);

    return text.split('{{link_start}}' + linkString + '{{link_end}}');
  };

  let initialTextSubstrings = createSubStringsArray(initialText);

  //First Link - end

  // if more links exist
  const existingLinks = initialText.match(/{{link_start}}/g) || []; // we need the amount of the links in the string
  if (initialText.match(/{{link_start}}/g)) {
    for (let i = 1; i < existingLinks.length; i++) {
      if (initialTextSubstrings[i].includes('{{link_start}}')) {
        const newText = createSubStringsArray(initialTextSubstrings[i]);
        initialTextSubstrings.pop();
        initialTextSubstrings.push(...newText);
      }
    }
  }
  // if more links exist -end
  return (
    <>
      {hasLink
        ? linksTexts.map((link, index) => {
            return (
              <Box key={index} sx={{ color }}>
                {initialTextSubstrings[index]}
                <Link
                  style={{ color: linkColor || colors.blue500 }}
                  key={index}
                  href={linkOnClick ? undefined : links[index]}
                  onClick={linkOnClick ? linkOnClick : undefined}
                  target={blankPage ? '_blank' : ''}
                  rel="noopener"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                >
                  {link}
                </Link>
                {initialTextSubstrings.length > 0
                  ? initialTextSubstrings[index + 1]
                  : null}
              </Box>
            );
          })
        : null}
    </>
  );
};

export default TextWithLinks;
