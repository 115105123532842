import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useUserCompanyCollection(params?: {
  page?: number;
  collection_id?: string;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: !!params?.collection_id,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 5000,
    staleTime: 5000,
    queryKey: ['user-company-suppliers', params],
    queryFn: async () => {
      let searchParams = new URLSearchParams();

      // searchParams.append('page', params?.page + '' || '1');
      const res = await axios.get(
        `${api.url}/user/company/collection/${
          params?.collection_id || ''
        }/overview/`,
        Object.assign(auth.requestConfig, { params: searchParams })
      );
      return res.data;
    },
  });
}
