import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import useCompany from 'hooks/useCompanyNEW';

import AppLoading from 'components/common/AppLoading';

import Company from './Company';

import { toJS } from 'mobx';
import Container from 'components/common/Container';
import CompanyDetailsPage from './CompanyDetailsPage/CompanyDetailsPage';

const Component = () => {
  const { slug } = useParams();
  const { data, isLoading } = useCompany(slug);

  return isLoading ? (
    <AppLoading />
  ) : (
    <Container pageContainer>
      <CompanyDetailsPage companyData={data} />
    </Container>
  );
};

export default Component;
