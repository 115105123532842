import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
  Slide,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from 'components/common/Divider';
import colors from 'constants/colors';
import { AddIcon } from 'components/icons/mui_icons';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  useUserCompanyCollections,
  useUserCompanyShortlistPost,
} from 'hooks/useUserCompanyCollections';
import Box from 'components/common/Box';

function CollectionAddButton({
  disabled,
  selectedCollectionIds,
  parentCollectionId,
  clearSelection,
}: {
  disabled?: boolean;
  selectedCollectionIds?: string[];
  parentCollectionId?: string;
  clearSelection?: () => void;
}) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
  const [newCollectionDialogOpen, setNewCollectionDialogOpen] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { refetch } = useUserCompanyCollections();

  const { isLoading: isPosting, mutate } = useUserCompanyShortlistPost();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.clientWidth);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl) && !disabled;

  const handleDialogOpen = () => {
    handleMenuClose();
    setNewCollectionName('');
    setErrorMessage(null);
    setSuccessMessage(null);
    setNewCollectionDialogOpen(true);
  };

  const handleDialogClose = () => {
    setNewCollectionDialogOpen(false);
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const handleConfirmCreate = () => {
    if (!selectedCollectionIds?.length || !newCollectionName.trim()) {
      setErrorMessage(
        t('Please enter a name and select at least one company.')
      );
      return;
    }

    mutate(
      {
        name: newCollectionName.trim(),
        companies: selectedCollectionIds,
        parent: parentCollectionId || '',
      },
      {
        onSuccess: async (data) => {
          setSuccessMessage(t('List created successfully!'));
          setErrorMessage(null);

          if (clearSelection) clearSelection();

          setNewCollectionDialogOpen(false);
          setSuccessMessage(null);
          await refetch();
          navigate(`/collections/${data?.id}/`);
        },
        onError: (err: any) => {
          console.error('POST failed:', err);
          setErrorMessage(
            err?.response?.data?.detail ||
              t('Failed to create list. Please try again.')
          );
        },
      }
    );
  };

  return (
    <>
      <Slide
        direction="up"
        in={selectedCollectionIds && selectedCollectionIds.length > 0}
      >
        <Fab
          variant="extended"
          onClick={handleDialogOpen}
          disabled={disabled}
          sx={{
            position: 'fixed',
            bottom: { xs: '16px', sm: '32px' },
            right: { xs: '16px', sm: '32px' },
            px: 3,
            py: 1.5,
            backgroundColor: isMenuOpen ? colors.neutral100 : colors.yellow500,
            color: colors.textLight900,
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '16px',
            borderTopRightRadius: isMenuOpen ? 0 : '16px',
            borderTopLeftRadius: isMenuOpen ? 0 : '16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            pointerEvents: disabled ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMenuOpen
                ? colors.neutral200
                : colors.yellow700,
            },
          }}
        >
          <Stack spacing={1} direction={'row'}>
            <AddIcon />
            <Box>
              {t('Add to list') + ` (${selectedCollectionIds?.length})`}
            </Box>
          </Stack>
        </Fab>
      </Slide>
      {isMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          slotProps={{
            paper: {
              style: {
                minWidth: menuWidth,
                borderBottomRightRadius: isMenuOpen ? 0 : '16px',
                borderBottomLeftRadius: isMenuOpen ? 0 : '16px',
              },
              elevation: 1,
            },
          }}
        >
          <MenuItem onClick={handleDialogOpen}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AddIcon />
              <Typography variant="body1">{t('Create a new list')}</Typography>
            </Stack>
          </MenuItem>
          {/* <Divider />
          <MenuItem onClick={() => null} disabled>
            <Stack direction="row" spacing={1} alignItems="center">
              <PlaylistAddIcon />
              <Typography variant="body1">
                {t('add to existing one')}
              </Typography>
            </Stack>
          </MenuItem> */}
        </Menu>
      )}

      <Dialog
        open={newCollectionDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('Name your new list')}</Typography>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}

            <TextField
              autoFocus
              placeholder={t('List name')}
              fullWidth
              variant="filled"
              value={newCollectionName}
              onChange={(e) => setNewCollectionName(e.target.value)}
              disabled={isPosting || !!successMessage}
              InputProps={{
                disableUnderline: true,
                sx: {
                  paddingTop: '0',
                  paddingBottom: '0',
                },
              }}
              InputLabelProps={{
                sx: {
                  '&.Mui-focused, &.MuiFormLabel-filled': {
                    transform: 'translate(12px, -18px) scale(0.75)',
                  },
                },
              }}
              sx={{
                '.MuiInputBase-input': {
                  pt: 2,
                  pb: 2,
                },
                '.MuiFilledInput-root': {
                  backgroundColor: colors.neutral100,
                  borderBottomLeftRadius: 16,
                  borderBottomRightRadius: 16,
                  fontSize: '16px',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            disabled={isPosting}
            color={'inherit'}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleConfirmCreate}
            disabled={
              !newCollectionName.trim() || isPosting || !!successMessage
            }
            color={'inherit'}
          >
            {t('Create')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CollectionAddButton;
