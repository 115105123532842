import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export function useUserCompanyCollections() {
  const auth = useAuth();
  return useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['user-company-collections'],
    queryFn: async () => {
      let searchParams = new URLSearchParams();

      const res = await axios.get(
        `${api.url}/user/company/collections/`,
        Object.assign(auth.requestConfig, { params: searchParams })
      );
      return res.data;
    },
  });
}

export function useUserCompanyShortlistPost() {
  const auth = useAuth();

  return useMutation({
    mutationFn: async (payload: {
      name: string;
      companies: string[];
      parent: string;
    }) => {
      //collection id
      const res = await axios.post(
        `${api.url}/user/company/collections/`,
        payload,
        auth.requestConfig
      );
      return res.data;
    },
  });
}
