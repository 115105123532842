import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCompany from 'hooks/useCompanyNEW';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CollectionBreadcrumbs({
  collectionName,
  collectionId,
  parentCollectionId,
  parentCollectionName,
  companyInCollectionSlug,
}: {
  collectionId: string;
  collectionName: string;
  parentCollectionId?: string;
  parentCollectionName?: string;
  companyInCollectionSlug?: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useCompany(companyInCollectionSlug);

  const companyInCollectionName = data?.name || '';

  return (
    <Stack spacing={1} pb={1} direction={'row'} alignItems={'center'}>
      <Link
        underline="hover"
        color="inherit"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate('/collections')}
      >
        {t('Programs')}
      </Link>
      {parentCollectionId ? (
        <>
          <ChevronRight color="inherit" sx={{ opacity: 0.15 }} />
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'pointer',
              opacity: 1.0,
            }}
            onClick={() => navigate(`/collections/${parentCollectionId}`)}
          >
            {parentCollectionName}
          </Link>
        </>
      ) : null}
      <ChevronRight color="inherit" sx={{ opacity: 0.15 }} />
      <Link
        underline="hover"
        color="inherit"
        sx={{ cursor: 'pointer', opacity: companyInCollectionSlug ? 1.0 : 0.4 }}
        onClick={() => navigate(`/collections/${collectionId}`)}
      >
        {collectionName}
      </Link>
      {companyInCollectionSlug ? (
        <>
          <ChevronRight color="inherit" sx={{ opacity: 0.15 }} />
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'pointer',
              opacity: 0.4,
            }}
            onClick={() =>
              navigate(
                `/collections/${collectionId}/${companyInCollectionSlug}`
              )
            }
          >
            {companyInCollectionName}
          </Link>
        </>
      ) : null}
    </Stack>
  );
}
