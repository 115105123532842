import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CircularProgress, // Import CircularProgress
} from '@mui/material';

import Button from 'components/common/Button';
import UnauthenticatedContainer from 'components/common/UnauthenticatedContainer';
import Typography from 'components/common/Typography/Typography';
import Link from 'components/common/Link';

import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import api from 'constants/api';
import Box from 'components/common/Box';
import LoginIcon from '@mui/icons-material/Login';
import colors from 'constants/colors';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      userToken: '',
      remember: false,
    },
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const auth = useAuth();

  const onSubmit = async (data: any) => {
    console.log('Form submitted with data:', data);
    setIsLoggingIn(true);
    try {
      if (auth.useToken) {
        auth.setToken(data.userToken);
      } else {
        await axios.post(
          `${api.url}/auth/login/`,
          { email: data.email, password: data.password },
          auth.requestConfig
        );
      }
      await auth.loadUserData();
      navigate('/home');
    } catch (error: any) {
      setErrorMessage(
        error?.response?.data?.detail || t('Login credentials invalid.')
      );
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <UnauthenticatedContainer
      title=""
      subtitle={t('Log in to your account.')}
      data-testid="login"
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            {auth.useToken ? (
              <TextField
                required
                fullWidth
                variant="filled"
                color="secondary"
                id="user-token"
                label="User Token"
                autoFocus
                error={!!errors.userToken}
                helperText={errors.userToken?.message}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    paddingTop: '0',
                    paddingBottom: '0',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    '&.Mui-focused, &.MuiFormLabel-filled': {
                      transform: 'translate(12px, -18px) scale(0.75)',
                    },
                  },
                }}
                sx={{
                  '.MuiInputBase-input': {
                    pt: 2,
                    pb: 2,
                  },
                  '.MuiFilledInput-root': {
                    backgroundColor: colors.neutral200,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    fontSize: '16px',
                  },
                }}
                {...register('userToken', {
                  required: t('User token is required'),
                })}
              />
            ) : (
              <>
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  color="secondary"
                  id="login-email"
                  label={t('Email Address')}
                  type="email"
                  autoComplete="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      paddingTop: '0',
                      paddingBottom: '0',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      '&.Mui-focused, &.MuiFormLabel-filled': {
                        transform: 'translate(12px, -18px) scale(0.75)',
                      },
                    },
                  }}
                  sx={{
                    '.MuiInputBase-input': {
                      pt: 2,
                      pb: 2,
                    },
                    '.MuiFilledInput-root': {
                      backgroundColor: colors.neutral200,
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      fontSize: '16px',
                    },
                  }}
                  {...register('email', { required: t('Email is required') })}
                />
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  color="secondary"
                  id="login-password"
                  label={t('Password')}
                  type="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      paddingTop: '0',
                      paddingBottom: '0',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      '&.Mui-focused, &.MuiFormLabel-filled': {
                        transform: 'translate(12px, -18px) scale(0.75)',
                      },
                    },
                  }}
                  sx={{
                    '.MuiInputBase-input': {
                      pt: 2,
                      pb: 2,
                    },
                    '.MuiFilledInput-root': {
                      backgroundColor: colors.neutral200,
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      fontSize: '16px',
                    },
                  }}
                  {...register('password', {
                    required: t('Password is required'),
                  })}
                />
              </>
            )}
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Box>
                <FormControlLabel
                  control={
                    <MuiCheckbox color="primary" {...register('remember')} />
                  }
                  label={t('Remember me')}
                />
              </Box>
              <Link
                underline="hover"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/forget')}
              >
                {t('Forgot password?')}
              </Link>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Button
              fullWidth
              sx={{ textTransform: 'none', fontWeight: 'bold' }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoggingIn}
              endIcon={
                isLoggingIn ? <CircularProgress size={20} /> : <LoginIcon />
              }
            >
              {isLoggingIn ? t('Logging in...') : t('Log In')}
            </Button>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'center'}
              spacing={2}
            >
              <Typography>{t("Don't have an account?")}</Typography>
              <Link
                underline="hover"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                href="https://theclimatechoice.com/en/plans-pricing/"
              >
                <Typography>{t('Create an account')}</Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </UnauthenticatedContainer>
  );
};

export default Login;
