import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from 'components/common/Typography';
import Button from 'components/common/Button';
import Stack from 'components/common/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { phCapture } from 'utils/posthog';

type SuppliersInviteDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  suppliersInvitationLink?: string;
};

const SuppliersInviteDialog = ({
  open,
  onClose,
  title,
  suppliersInvitationLink,
}: SuppliersInviteDialogProps) => {
  const { t } = useTranslation('common');
  const [copySuccess, setCopySuccess] = useState<string>(
    t('Copy link and invite your suppliers')
  );

  const handleCopy = () => {
    if (suppliersInvitationLink) {
      navigator.clipboard.writeText(suppliersInvitationLink);
      setCopySuccess(t('Copied!'));
      phCapture('copy supplier invite link');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography variant="body1">
            {t(
              'Copy the invite link below and email it to your suppliers. Once they have followed the instructions and completed the onboarding process, you will be able to see an assessment of their climate maturity and can begin to deepen your analysis.'
            )}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopy}
          >
            {copySuccess}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuppliersInviteDialog;
