import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import colors from 'constants/colors';

// find ApexAxisChartSeries type somewhere
// type seriesElement = {
//   name: string;
//   data: Array<string | number | null>;
// }

type Props = {
  horizontal?: boolean;
  yaxisTitle?: string;
  categories?: string[] | number[];
  series?: any[];
  height?: number;
  width?: string;
};

const defaultLineColors = [
  colors.green300,
  colors.blue500,
  colors.tertiary500,
  colors.neutral500,
  colors.green200,
  colors.blue500,
  colors.neutral200,
  colors.tertiary800,
  colors.neutral800,
];

function Component({
  categories,
  series,
  yaxisTitle,
  height = 450,
  width,
}: Props) {
  const options = {
    markers: {
      size: 6,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: { text: yaxisTitle },
    },
    chart: {
      height: height ? height : 380,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    colors: defaultLineColors,
    dataLabels: {
      style: {
        colors: defaultLineColors,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      width={width ? width : '99%'}
      type="line"
      height={height}
    />
  );
}

export default observer(Component);
