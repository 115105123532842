import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Link from 'components/common/Link';
import { LanguageIcon } from 'components/icons/mui_icons/index';

import EmptyTextField from 'components/common/EmptyTextField';
import colors from 'constants/colors';

type Props = {
  website: string | undefined;
};

function Website({ website }: Props) {
  const { t } = useTranslation('common');

  if (
    website &&
    !website.startsWith('https://') &&
    !website.startsWith('http://')
  ) {
    website = 'https://' + website;
  }

  return (
    <Stack direction={'row'} width={'220px'} marginTop={'20px'}>
      <LanguageIcon sx={{ color: colors.neutral600 }} />
      <Grid marginLeft={'20px'}>
        <Typography variant="caption" color={colors.neutral600}>
          {t('Website')}
        </Typography>
        <Grid marginTop={'8px'}>
          {website ? (
            <Link
              color="inherit"
              href={website}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography
                variant="body1"
                marginTop={'8px'}
                width={'180px'}
                noWrap
                textOverflow="ellipsis"
              >
                {website}
              </Typography>
            </Link>
          ) : (
            <EmptyTextField />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default observer(Website);
