import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import useCompany from 'hooks/useCompany';
import useCompanies from 'hooks/useCompanies';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import { StarIcon, StarBorderIcon } from 'components/icons/mui_icons';
import Button from 'components/common/Button';
import colors from 'constants/colors';

type Props = {
  rowId?: string;
};

function SaveFavoriteButton({ rowId }: Props) {
  const { t } = useTranslation('common');
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { count } = useCompanies();
  const { companyData } = useCompany();
  const companyId = companyData?.id;
  const favorites = auth.favorites;
  let id: string | undefined = '';
  if (rowId) {
    id = rowId;
  } else {
    id = companyId;
  }

  // Edge case(favorites page) -  when the user is remove favorites tag
  // from the end of the list we need to go to previous page on pagination element
  // exception on page = 1
  // otherwise the goTo breaks because the page doesn't exist
  //FIX-QA
  const changePaginationPage = () => {
    const pageNumber = Number(searchParams.get('page'));
    const prevPageNumber = pageNumber - 1;
    const prodCountFromPage = prevPageNumber * 10 + 1;

    if (prodCountFromPage === count) {
      navigateSearch({
        pathname: location.pathname,
        params: {
          page: pageNumber === 1 ? 1 : prevPageNumber,
        },
        replace: false,
      });
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (location.pathname === '/companies/watchlist') {
      changePaginationPage();
    }
    auth.toggleFavoriteCompany(id);
  };

  return id ? (
    <Button
      variant="text"
      color="secondary"
      sx={{
        fontSize: '11px', // <-- need to keep this to one line
      }}
      startIcon={
        favorites.includes(id) ? (
          <StarIcon sx={{ color: colors.blue400 }} />
        ) : (
          <StarBorderIcon sx={{ color: colors.blue400 }} />
        )
      }
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        handleClick(e);
      }}
    >
      {t('Add to watchlist')}
    </Button>
  ) : null;
}

export default observer(SaveFavoriteButton);
