import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';

import Button from 'components/common/Button/Button';
import Card from 'components/common/Card';

import colors from 'constants/colors';
import LaunchIcon from '@mui/icons-material/Launch';

type Props = {
  title: string;
  link: string | undefined;
};

const SuppliersInviteCard = ({ title, link }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Card backgroundColor={colors.blue500} noBoxShadow>
      <Grid container spacing={4} alignItems={'center'}>
        <Grid item xs={12} lg={8}>
          <Stack spacing={1}>
            <Typography color={colors.white} variant="h5">
              {title}
            </Typography>

            <Typography color={colors.white} variant="body1">
              {t(
                'The Climate Performance Assessment enables businesses to analyse gaps against international climate reporting standards and benchmark against other businesses.'
              )}
            </Typography>
            <Typography color={colors.white} variant="body1">
              {t('Click the button to start.')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            sx={{ maxWidth: '300px' }}
            variant="whiteBackground"
            endIcon={<LaunchIcon />}
            onClick={() => {
              window.open(link);
            }}
          >
            {t('Start here')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SuppliersInviteCard;
