import React, { useEffect, useState } from 'react';
import {
  BaseCheckboxPropsOverrides,
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UpdateIcon from '@mui/icons-material/Update';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import KeyIcon from '@mui/icons-material/KeyOutlined';
import NoEncryptionOutlinedIcon from '@mui/icons-material/NoEncryptionOutlined';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { useUserCompanyCollections } from 'hooks/useUserCompanyCollections';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Check from 'components/icons/Check';
import Close from '@mui/icons-material/Close';
import Tooltip from 'components/common/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Badge } from '@mui/material';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { fontSize } from 'constants/typography';
import Avatar from 'components/common/Avatar';
import { getCountryName } from 'utils/getCountryName';
import InviteDialog from './InviteDialog';
import { CloseIcon } from 'components/icons/mui_icons';
import { LockOutlined } from '@mui/icons-material';
import CollectionAddButton from './CollectionAddButton';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Define a custom checkbox component with proper TypeScript props.
interface CustomCheckboxProps extends CheckboxProps {}

const CustomCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => (
    <Checkbox
      {...props}
      color="secondary"
      // Use the imported icon for the checked state. "inherit" lets it pick up the sx color.
      checkedIcon={
        <CheckBoxOutlinedIcon
          sx={{
            background:
              'radial-gradient(circle, yellow 0%, yellow 50%, transparent 60%)',
          }}
        />
      }
    />
  )
) as React.ElementType<CheckboxProps & BaseCheckboxPropsOverrides>;

interface ScoreProps {
  score?: number; // score is optional and ranges from 0 to 100
}

const ScoreComponent: React.FC<ScoreProps> = ({ score = 0 }) => {
  // Normalize score to fit within a scale of 0-10
  const normalizedScore = Math.round((score / 100) * 10);

  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      {/* Display the numerical score */}
      <Typography variant="body1" sx={{ marginRight: 1, width: '24px' }}>
        {score}
      </Typography>

      {/* Display the score bar */}
      <Box display="flex">
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            width={6}
            height={16}
            borderRadius={1}
            marginRight={'2px'}
            bgcolor={index < normalizedScore ? colors.blue400 : colors.blue200}
          />
        ))}
      </Box>
    </Box>
  );
};

function QualatativeDescripterChip({
  type,
}: {
  type?: 'VERY_GOOD' | 'GOOD' | 'FAIR' | 'POOR';
}) {
  const { t } = useTranslation();
  return type ? (
    <Chip
      label={
        type === 'GOOD' ? t('Good') : type === 'FAIR' ? t('Fair') : t('Poor')
      }
      style={{
        borderRadius: 4,
        fontSize: '12px',
        color: colors.neutral600,
        height: '24px',
        backgroundColor:
          type === 'GOOD'
            ? colors.green100
            : type === 'FAIR'
            ? colors.warning100
            : colors.tertiary100,
      }}
    />
  ) : null;
}

function UserCompanyCollectionListDataGrid({
  rows = [],
  collectionId,
  hasCollections,
  loading,
  isSupplierCollection = false,
  isFiltered = false,
}: {
  rows: any;
  collectionId?: string;
  hasCollections?: boolean;
  loading?: boolean;
  isSupplierCollection?: boolean;
  isFiltered?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  const showFakeData = collectionId === 'example';

  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);

  const sendNotification = async (
    targetCompanyName: string,
    targetCompanyId: string
  ) => {
    try {
      const formData = new FormData();
      formData.set('subject', '[Support] Info request: Public screening');
      formData.set(
        'body',
        `
              user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
              user email: ${auth.userDetails.email}
              user company: ${userCompany.name}

              target company: ${targetCompanyName}
              target company id: ${targetCompanyId}
            `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };

  const { mutate: createRequest, isLoading: isLoadingCreate } =
    useAccessRequestCreate();

  const { data, isLoading, refetch, isRefetching } =
    useUserCompanyCollectionOverview({
      collection_id: collectionId !== 'example' ? collectionId : undefined,
    });

  const rowCount = rows.length;

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Name'),
      flex: 2,
      minWidth: 250,
      sortable: true,
      renderCell: (params) => (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          maxWidth="100%"
          sx={{
            minWidth: 0,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            maxWidth="100%"
            onClick={() =>
              navigate(
                `/collections/${collectionId}/${
                  params?.row.reporting_company_slug || params?.row.company_slug
                }/`
              )
            }
            sx={{
              cursor: 'pointer',
              minWidth: 0,
              p: 1,
              ml: -1,
              borderRadius: 1,
              flex: 1,
              ':hover': {
                background: colors.neutral100,
              },
            }}
          >
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                color: colors.white,
                backgroundColor:
                  params.row.company_logo || params.row.reporting_company_logo
                    ? 'none'
                    : colors.neutral200,
                borderRadius: 0.5,
                '& img': {
                  objectFit: 'contain',
                },
              }}
              src={params.row.company_logo || params.row.reporting_company_logo}
              alt={params.row.company_name}
            >
              <BusinessIcon fontSize="small" />
            </Avatar>
            <Stack
              spacing={0}
              justifyContent="center"
              sx={{
                minWidth: 0,
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <Typography
                  noWrap
                  variant="body1"
                  title={params.row.company_name}
                >
                  {params.row.company_name}
                </Typography>
                {params.row.company_country ? (
                  <Typography
                    variant="body1"
                    color="inherit"
                    sx={{ opacity: 0.5, pl: 0.5 }}
                    title={getCountryName(params.row.company_country)}
                  >
                    {params.row.company_country}
                  </Typography>
                ) : null}
              </Stack>

              {params.row.reporting_company_name ? (
                <Box sx={{ minWidth: 0, overflow: 'hidden' }}>
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    spacing={1}
                    sx={{
                      color: colors.onSurface200,
                      opacity: 0.6,
                      minWidth: 0,
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    <FileCopyOutlinedIcon fontSize="inherit" color="inherit" />
                    <Typography
                      noWrap
                      variant="body2"
                      color="inherit"
                      title={params.row.reporting_company_name}
                    >
                      {params.row.reporting_company_name}
                    </Typography>
                    {params.row.reporting_company_country ? (
                      <Typography
                        variant="body2"
                        color="inherit"
                        sx={{ opacity: 0.8, pl: 0.5 }}
                        title={getCountryName(
                          params.row.reporting_company_country
                        )}
                      >
                        {params.row.reporting_company_country}
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>
              ) : null}
            </Stack>
          </Stack>
        </Box>
      ),
    },
    {
      field: 'climate_score',
      headerName: t('ClimateChoice Score'),
      flex: 0,
      minWidth: 150,
      sortable: true,
      sortComparator: (v1, v2) => {
        // Return negative if v1 should come before v2.
        // Normally ascending would do v1 - v2, but we flip it:
        return v2 - v1;
      },
      renderCell: (params) => (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          maxWidth="100%"
          sx={{
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <Stack
            spacing={2}
            pt={0.5}
            pb={0.5}
            alignItems="center"
            justifyContent={'center'}
            onClick={() =>
              navigate(
                `/collections/${collectionId}/${params?.row.company_slug}/`
              )
            }
            sx={{
              cursor: 'pointer',
              borderRadius: 2,
              flex: 1,
              ':hover': {
                background: colors.neutral100,
              },
            }}
          >
            <ScoreComponent
              score={getClimateScoreAsPercent(params.row.climate_score)}
            />
          </Stack>
        </Box>
      ),
    },
    {
      field: 'maturity_level',
      headerName: t('Maturity'),
      flex: 0,
      width: 90,
      sortable: true,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const order = ['VERY_GOOD', 'GOOD', 'FAIR', 'POOR'];
        // Determine the indices of v1 and v2 in the predefined order
        const i1 = order.indexOf(v1);
        const i2 = order.indexOf(v2);

        // If a value isn't found, you may decide how to handle it.
        // For now, let's place unfound values at the end.
        const safeI1 = i1 === -1 ? order.length : i1;
        const safeI2 = i2 === -1 ? order.length : i2;

        return safeI1 - safeI2;
      },
      renderCell: (params) => {
        return (
          <Box display={'flex'} height={'100%'} alignItems={'center'}>
            {params.row.maturity_level_self_reported ? (
              <Tooltip
                title={
                  <Stack spacing={2}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <InfoOutlined
                        sx={{ transform: 'scale(1.2)', fontSize: '15px' }}
                      />
                      <Box>{t('Self reported data exists.')}</Box>
                    </Stack>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Box>{t('Self reported maturity') + ':'}</Box>
                      <Box>
                        <QualatativeDescripterChip
                          type={params.row.maturity_level_self_reported}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                }
              >
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: colors.white,
                      padding: 0,
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      minHeight: '15px',
                    },
                  }}
                  badgeContent={
                    <InfoOutlined
                      sx={{
                        transform: 'scale(1.2)',
                        fontSize: '15px',
                        color: colors.neutral400,
                      }}
                    />
                  }
                >
                  <QualatativeDescripterChip type={params.row.maturity_level} />
                </Badge>
              </Tooltip>
            ) : (
              <QualatativeDescripterChip type={params.row.maturity_level} />
            )}
          </Box>
        );
      },
    },
    {
      field: 'public_data_screening_last_date',
      headerName: t('Public Screening'),
      flex: 1,
      minWidth: 130,
      sortable: true,
      renderCell: (params) => {
        const lastPublicScreeningDate = getDurationBetweenDates(
          params.row.public_data_screening_last_date,
          new Date().toUTCString()
        );
        return (
          <Box
            display={'flex'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              sx={{ maxWidth: '100%' }}
            >
              {params.row.public_data_screening_last_date ? (
                <Stack
                  spacing={1.5}
                  direction={'row'}
                  sx={{
                    borderRadius: '4px',
                    backgroundColor: colors.green100,
                    height: '32px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Check
                    sx={{ opacity: 0.4, color: colors.green500 }}
                    color="inherit"
                    fontSize="small"
                  />
                </Stack>
              ) : params.row.public_data_screening_status === 'IN_PROGRESS' ? (
                <Box maxWidth={'100%'}>
                  <Stack
                    spacing={1.5}
                    direction={'row'}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: colors.neutral100,
                      height: '32px',
                      pl: 1.5,
                      pr: 1.5,
                      alignItems: 'center',
                      maxWidth: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        width: 10,
                        minWidth: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: colors.warning500,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ opacity: 0.6, width: '100%' }}
                      noWrap
                      title={t('Screening in progress')}
                    >
                      {t('Screening in progress')}
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                <Button
                  variant="text"
                  color="inherit"
                  disabled={
                    showFakeData || notificationSending || notificationSent
                  }
                  onClick={() => {
                    sendNotification(
                      params.row.company_name,
                      params.row.company_uuid
                    );
                  }}
                  sx={{
                    whiteSpace: 'nowrap',
                    alignSelf: 'center', // Ensures the button aligns in the center
                  }}
                  startIcon={notificationSent ? <Check /> : <AutoFixHighIcon />}
                >
                  {notificationSent ? t('Requested') : t('Request')}
                </Button>
              )}

              {lastPublicScreeningDate ? (
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  alignItems={'center'}
                  sx={{ opacity: 0.6, overflow: 'hidden' }}
                >
                  <CachedOutlinedIcon fontSize="small" color="inherit" />

                  <Typography variant="body2" noWrap>
                    {lastPublicScreeningDate}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        );
      },
    },
    {
      field: 'private_data_user',
      headerName: t('Has User'),
      renderHeader: () => <></>,
      flex: 0,
      width: 40,
      minWidth: 40,
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            {params?.row?.private_data_user ? (
              <AccountCircleOutlinedIcon
                fontSize="small"
                sx={{ color: colors.neutral400 }}
              />
            ) : (
              <NoAccountsOutlinedIcon fontSize="small" sx={{ opacity: 0.1 }} />
            )}
          </Stack>
        );
      },
    },
    {
      field: 'private_data_status',
      headerName: t('Has Data Access'),
      renderHeader: () => <></>,
      flex: 0,
      width: 40,
      minWidth: 40,
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            {params?.row?.private_data_user &&
            params?.row?.private_data_status !== 'ACCESS_GRANTED' ? (
              <LockOutlined
                fontSize="small"
                sx={{ color: colors.neutral400 }}
              />
            ) : params?.row?.private_data_user &&
              params?.row?.private_data_status === 'ACCESS_GRANTED' ? (
              <Check
                fontSize="small"
                sx={{ color: colors.neutral400, opacity: 0.2 }}
              />
            ) : params?.row?.id === userCompany.id ? (
              <NoEncryptionOutlinedIcon
                fontSize="small"
                sx={{ color: colors.neutral400, opacity: 0.2 }}
              />
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'private_data_crc_last_date',
      headerName: t('Last CRC'),
      flex: 0,
      minWidth: 80,
      sortable: true,
      filterable: false,
    },
    {
      field: 'private_data_cpa_last_date',
      headerName: t('Last CPA'),
      flex: 0,
      minWidth: 80,
      sortable: true,
      filterable: false,
    },

    {
      field: 'private_data_confidential_responses',
      headerName: t('Confidential Responses'),
      flex: 1,
      minWidth: 190,
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        const lastPrivateDataUpdate = getDurationBetweenDates(
          params.row.private_data_cpa_last_date,
          new Date().toUTCString()
        );
        return (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            height={'100%'}
            sx={{ maxWidth: '100%' }}
          >
            {params.row.id === userCompany.id ? (
              <Box sx={{ opacity: 0.5 }}>
                <i>{t('My Company')}</i>
              </Box>
            ) : params.row.private_data_cpa_status === 'PENDING_RESPONSE' ||
              params.row.private_data_cpa_status === 'EXPIRED' ? (
              <>
                <Stack
                  spacing={1.5}
                  direction={'row'}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: colors.neutral100,
                    height: '32px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                    maxWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: 10,
                      minWidth: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: colors.warning500,
                    }}
                  />
                  <Typography variant="body2" sx={{ opacity: 0.6 }} noWrap>
                    {params.row.private_data_cpa_status === 'EXPIRED'
                      ? t('Expired Link')
                      : t('Pending Assessment')}
                  </Typography>
                </Stack>
              </>
            ) : params.row.private_data_status === 'ACCESS_GRANTED' &&
              lastPrivateDataUpdate ? (
              <>
                <Stack
                  spacing={1.5}
                  direction={'row'}
                  sx={{
                    borderRadius: '4px',
                    backgroundColor: colors.green100,
                    height: '32px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                  }}
                >
                  <Check
                    sx={{ opacity: 0.4, color: colors.green500 }}
                    color="inherit"
                    fontSize="small"
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  alignItems={'center'}
                  sx={{ opacity: 0.6, overflow: 'hidden' }}
                >
                  <CachedOutlinedIcon fontSize="small" color="inherit" />

                  <Typography variant="body2" noWrap>
                    {lastPrivateDataUpdate}
                  </Typography>
                </Stack>
              </>
            ) : params.row.private_data_status === 'ACCESS_REQUESTED' ? (
              <>
                <Stack
                  spacing={1.5}
                  direction={'row'}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: colors.neutral100,
                    height: '32px',
                    pl: 1.5,
                    pr: 1.5,
                    alignItems: 'center',
                    maxWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: 10,
                      minWidth: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: colors.warning500,
                    }}
                  />
                  <Typography
                    title={t('Pending access request')}
                    variant="body2"
                    sx={{ opacity: 0.6 }}
                    noWrap
                  >
                    {t('Pending access request')}
                  </Typography>
                </Stack>
              </>
            ) : params?.row.private_data_user &&
              params?.row.private_data_status !== 'ACCESS_GRANTED' &&
              !!(
                params?.row.private_data_crc_last_date ||
                params?.row.private_data_cpa_last_date
              ) ? (
              <Stack spacing={1} direction={'row'}>
                <Button
                  color="inherit"
                  variant="text"
                  startIcon={<SendOutlined />}
                  disabled={
                    showFakeData || isLoadingCreate || isLoading || isRefetching
                  }
                  onClick={() => {
                    if (
                      userCompany?.id &&
                      params.row?.id &&
                      confirm(
                        t(
                          'Requesting access will notify a representitive at {{target_company}}. Do you wish to continue?',
                          { target_company: params.row.company_name }
                        )
                      )
                    ) {
                      createRequest({
                        data: {
                          data_receiver: userCompany?.id,
                          data_provider: params.row.id,
                        },
                        onSuccess: () => {
                          refetch();
                          snackbarMessage.addMessage({
                            message: t('Request sent'),
                          });
                        },
                      });
                    }
                  }}
                >
                  {t('Request access')}
                </Button>
              </Stack>
            ) : params.row.private_data_user &&
              !params.row.private_data_cpa_last_date &&
              !!params.row.private_data_crc_last_date ? (
              <Button
                color="inherit"
                variant="text"
                startIcon={<SendOutlined />}
                disabled
                onClick={() => {
                  setInviteDialogOpen(true);
                }}
              >
                {t('Request')}
              </Button>
            ) : !params.row.private_data_crc_last_date &&
              (isSupplierCollection || showFakeData) ? (
              <Button
                color="inherit"
                variant="text"
                startIcon={<SendOutlined />}
                onClick={() => {
                  setInviteDialogOpen(true);
                }}
              >
                {t('Invite')}
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params: GridRowParams) => [
        ...(!!params.row.reporting_company_slug
          ? [
              <GridActionsCellItem
                disabled={showFakeData}
                icon={<OpenInNewIcon />}
                onClick={() =>
                  window.open(`/company/${params.row.reporting_company_slug}`)
                }
                label={t('View reporting company profile')}
                showInMenu
              />,
            ]
          : []),
        <GridActionsCellItem
          disabled={showFakeData}
          icon={<OpenInNewIcon />}
          onClick={() => window.open(`/company/${params.row.company_slug}`)}
          label={t('View company profile')}
          showInMenu
        />,
      ],
    },
  ];
  const localeText = {
    ...(i18n.language === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: isFiltered
      ? t('No results match your filters.')
      : t('This collection is empty.'),
  };

  // Parse initial sorting state from URL
  const initialSortField = searchParams.get('sortField') || 'company_name';
  const initialSortDirection =
    (searchParams.get('sortDirection') as GridSortDirection) || 'asc';

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: initialSortField, sort: initialSortDirection },
  ]);

  // Handle user-triggered sorting changes
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      const { field, sort } = newModel[0];

      // Update the sortModel state
      setSortModel(newModel);

      // Update the URL search params
      const newParams: Record<string, string> = { sortField: field };
      if (sort) {
        newParams.sortDirection = sort;
      }
      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()), // Preserve existing params
          ...newParams,
        },
        { replace: true }
      );
    }
  };

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { data: collections } = useUserCompanyCollections();

  const collectionIndex = collections?.findIndex(
    (d: any) => d.id === collectionId
  );
  const collection =
    !collections || collectionIndex === -1
      ? collections
        ? collections[0]
        : {}
      : collections[collectionIndex];

  return (
    <Box minHeight="400px">
      <DataGrid
        loading={loading}
        localeText={localeText}
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50, page: 0 }, // Ensures the default page size is 100
          },
          columns: {
            columnVisibilityModel: {
              private_data_crc_last_date: false,
              private_data_cpa_last_date: false,
            },
          },
        }}
        checkboxSelection={!collection?.parent}
        rowHeight={70}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection as string[]);
        }}
        pageSizeOptions={[50]}
        slots={{
          baseCheckbox: CustomCheckbox,
        }}
        sx={{
          minHeight: hasCollections ? undefined : 400,
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            boxShadow: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: colors.neutral50,
          },
        }}
      />
      <InviteDialog
        title={t('Invite Suppliers')}
        suppliersInvitationLink={userCompany.supplier_invite_link}
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
      />
      {!collection?.parent ? (
        <CollectionAddButton
          disabled={selectedRows.length === 0}
          selectedCollectionIds={selectedRows}
          parentCollectionId={collectionId}
          clearSelection={() => setSelectedRows([])}
        />
      ) : null}
    </Box>
  );
}

export default UserCompanyCollectionListDataGrid;
