import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';
import RFIDialog from './RFIDialog';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Card from 'components/common/Card';
import ScoreRadialSmall from './ScoreRadialSmall';
import ClimateImpactModel from 'components/icons/crc/crc_icons/ClimateImpactModel';
import moduleActions from './module_actions';
import { useCallback, useState } from 'react';
import Button from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from 'utils/browser';
import { toJS } from 'mobx';

const Component = ({ score = 0 }) => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const { userCompany } = useUserCompany();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const { level1, level2, level3 } = moduleActions();

  const setOpenRFI = useCallback(
    (subject = '') => {
      setSubject(subject);
      setOpen(!open);
      return true;
    },
    [open]
  );

  const { isLoading, isFetching, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['user-company-crc-report'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/crc/report/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const textObj =
    data?.result === 1
      ? level1?.decarbonization_measures
      : data?.result === 3
      ? level3?.decarbonization_measures
      : undefined;

  return (
    <Card>
      <Stack spacing={2} onClick={() => false}>
        <ScoreRadialSmall
          title={t('Decarbonization Measures')}
          info={t(
            'The Decarbonization Measures category reflects the operational actions in past, present and future timeframes that have an impact on emissions.'
          )}
          score={score}
          // breakdown={[
          //   {
          //     question: 'Have you taken any decarbonisation measures?',
          //     score: 0.5,
          //   },
          //   {
          //     question: 'Are you engaging your supply chain?',
          //     score: 0.5,
          //   },
          //   {
          //     question: 'Are you using renewable energy?',
          //     score: 1,
          //   },
          //   {
          //     question: 'Have you taken any action to improve energy efficiency?',
          //     score: 0.5,
          //   },
          // ]}
          breakdown={userCompany?.score_breakdown
            ?.filter((s: any) => {
              return s.category === 'Climate Actions';
            })
            .map((s: any) => {
              return {
                question: s.question_text,
                score: s.score,
              };
            })}
          icon={<ClimateImpactModel color={colors.blue500} />}
        />
        {textObj ? (
          <Card backgroundColor={colors.warning100} noBoxShadow>
            <Stack spacing={1.5}>
              <Typography variant="h5" sx={{ color: colors.neutral600 }}>
                {textObj.title}
              </Typography>
              <Typography variant="body1" sx={{ color: colors.neutral600 }}>
                {textObj.subtitle}
              </Typography>
              <Box />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    navigate(textObj.recommendationLink);
                    scrollToTop();
                  }}
                >
                  {textObj.recommendationText}
                </Button>
                <Button
                  size="large"
                  color="warning"
                  variant="text"
                  onClick={() =>
                    setOpenRFI(`${t('Request support')} - ${textObj.title}`)
                  }
                >
                  {t('Request support')}
                </Button>
              </Stack>
            </Stack>
          </Card>
        ) : null}
      </Stack>
      <RFIDialog
        open={open}
        subject={subject}
        closeDialog={setOpenRFI}
        identifier={userCompany.id}
        companyName={userCompany.slug}
        userDetails={auth.userDetails}
      />
    </Card>
  );
};

export default Component;
