// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import api from 'constants/api';

import Container from 'components/common/Container';

import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import AppLoading from 'components/common/AppLoading/AppLoading';
import Stack from 'components/common/Stack/Stack';
import { useState } from 'react';
import CompanySelect from 'components/common/CompanySelect';
import CompanyDetails from './CompanyDetails';
import CompanyDataServices from './CompanyDataServices';
import CompanyDataServicesToolbar from './CompanyDataServicesToolbar';
import CompanyCollections from './CompanyCollections';
import useAdminCompany from 'hooks/useAdminCompany';
import CompanyCannedSearches from './CompanyCannedSearches';
import Box from 'components/common/Box';

function Component() {
  const auth = useAuth();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [codeError, setCodeError] = useState(false);
  const { t } = useTranslation('common');

  const { isLoading, isError, refetch, data } = useAdminCompany(companyId);
  return (
    <Container pageContainer maxWidth="xl" sx={{ pt: 3 }}>
      <Stack spacing={5}>
        <Box maxWidth={'320px'}>
          <CompanySelect
            value={companyId}
            onChange={(value) => {
              if (value) {
                navigate(`/admin/company/${value}`);
              } else {
                navigate('/admin/company');
              }
            }}
            placeholder={t('Select company')}
          />
        </Box>
        {companyId && data ? (
          <>
            <CompanyDetails />
            <CompanyCannedSearches />
            <CompanyDataServicesToolbar company={data} />
            <CompanyDataServices />
            <CompanyCollections companyId={data.id} />
          </>
        ) : null}
      </Stack>
    </Container>
  );
}

export default observer(Component);
