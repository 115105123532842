import colors from 'constants/colors';
import { PaletteOptions } from '@mui/material';

const tccPalette = {
  primary: {
    dark: colors.yellow700,
    main: colors.yellow500,
    light: colors.yellow200,
    contrastText: colors.textLight900,
  },
  secondary: {
    dark: colors.neutral700,
    main: colors.neutral500,
    light: colors.neutral200,
    contrastText: colors.textLight700,
  },
} as PaletteOptions;

export default tccPalette;
