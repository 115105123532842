import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Box,
  Paper,
  Avatar,
} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import colors from 'constants/colors';
import IconButton from 'components/common/IconButton';

interface FlatCollection {
  id: string;
  name: string;
  parent: string | null;
}

interface CollectionTree extends FlatCollection {
  children?: CollectionTree[];
}

function buildCollectionTree(flat: FlatCollection[]): CollectionTree[] {
  const map = new Map<string, CollectionTree>();
  const roots: CollectionTree[] = [];

  flat.forEach((item) => {
    map.set(item.id, { ...item, children: [] });
  });

  flat.forEach((item) => {
    if (item.parent) {
      const parent = map.get(item.parent);
      if (parent) {
        parent.children!.push(map.get(item.id)!);
      }
    } else {
      roots.push(map.get(item.id)!);
    }
  });

  return roots;
}

export default function CollectionList({
  rows = [],
  loading,
}: {
  rows: FlatCollection[];
  loading?: boolean;
}) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const navigate = useNavigate();

  const handleExpand = (id: string) => {
    setExpanded((prev) => (prev === id ? false : id));
  };

  const handleNavigate = (id: string) => {
    navigate(`/collections/${id}`);
  };

  // Convert flat rows to tree structure
  const collections = useMemo(() => buildCollectionTree(rows), [rows]);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 1,
          border: '1px solid #E0E0E0',
          overflow: 'hidden',
        }}
      >
        {collections.map((collection, index) => (
          <Accordion
            key={collection.id}
            expanded={expanded === collection.id}
            onChange={() => null}
            disableGutters
            elevation={0}
            sx={{
              borderBottom:
                index == collections.length - 1 ? '1px solid #E0E0E0' : 'none',
              '&:before': { display: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton
                  disabled={
                    !(collection.children && collection.children.length > 0)
                  }
                  sx={{
                    opacity: !(
                      collection.children && collection.children.length > 0
                    )
                      ? 0.4
                      : 1,
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleExpand(collection.id);
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              sx={{
                minHeight: 64,
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-content': {
                  marginLeft: 1,
                  alignItems: 'center',
                },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                width="100%"
                onClick={() => handleNavigate(collection.id)}
                sx={{
                  cursor: 'pointer',
                  minWidth: 0,
                  p: 1,
                  ml: -1,
                  borderRadius: 1,
                  flex: 1,
                  ':hover': {
                    background: colors.neutral100,
                  },
                }}
              >
                <Avatar
                  sx={{
                    width: '36px',
                    height: '36px',
                    color: colors.white,
                    backgroundColor: colors.neutral200,
                    borderRadius: '8px',
                    '& img': {
                      objectFit: 'contain',
                    },
                  }}
                  alt={collection.name}
                >
                  <ListAltIcon />
                </Avatar>
                <Typography variant="body1" flex={1}>
                  {collection.name}
                </Typography>
              </Stack>
            </AccordionSummary>

            {collection.children && collection.children.length > 0 && (
              <AccordionDetails sx={{ pl: 9, pb: 2 }}>
                <Stack spacing={1}>
                  {collection.children.map((child) => (
                    <Typography
                      key={child.id}
                      variant="body2"
                      color="text.primary"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                      onClick={() => handleNavigate(child.id)}
                    >
                      {child.name}
                    </Typography>
                  ))}
                </Stack>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Paper>
    </Box>
  );
}
