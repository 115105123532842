import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import CardMedia from 'components/common/CardMedia';
import KeyFactsCard from 'components/common/CompanyKeyFacts/KeyFactsCard/KeyFactsCard';
import Button from 'components/common/Button/Button';
import { imageSrcMap } from 'media/crc_start_icon/index';
import { InfoOutlinedIcon } from 'components/icons/mui_icons';

import useAuth from 'hooks/useAuth';
import useSignUp from 'hooks/useSignUp';
import Stack from 'components/common/Stack';
import { toJS } from 'mobx';

const PendingEmailVerification = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { resendEmail, hasResentEmail } = useSignUp();

  return (
    <Grid container justifyContent="center" alignItems="center" padding={4}>
      <Stack alignItems={'center'}>
        <Typography variant={'h4'} color={'black'}>
          {t(`You’re almost there`)}
        </Typography>
        <Box
          maxWidth={'sm'}
          mt={2}
          justifyContent={'center'}
          alignItems="center"
        >
          <KeyFactsCard
            icon={<InfoOutlinedIcon />}
            text={t(
              `We’ve sent you an email to ` +
                toJS(auth.userDetails.email) +
                `. Please follow the instructions within to verify your email address in order to continue.`
            )}
            width={'100%'}
          />
          <Typography
            variant={'body1'}
            color={'neutral.contrastText'}
            align={'center'}
            mb={2}
          >
            {t(
              `After verifying your email, we’ll ask you to take our Climate Readiness Check. This is a short set of questions that enable your organization to understand its climate maturity with respect to internationally recognised standards and objectives. Using your answers, we create a report that provides you with an analysis of your strengths and opportunities for improvement, along with actionable recommendations for next steps.`
            )}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="button"
            onClick={() => resendEmail({ auth: auth })}
            disabled={hasResentEmail}
          >
            {t('Resend Verification Email')}
          </Button>
        </Box>
      </Stack>
    </Grid>
  );
};

export default observer(PendingEmailVerification);
