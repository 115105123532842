import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Card from 'components/common/Card';
import CardMedia from 'components/common/CardMedia';
import Button from 'components/common/Button';
import UserDashboardCRCHeader from 'components/common/UserDashboardCRCHeader';

import colors from 'constants/colors';
import { dashboardImages } from 'media/user_dashboard';

type Props = {
  title: string;
  subtitle?: string;
  buttonText: string;
  backgroundColor?: string;
  textColor?: string;
  onClick: () => void;
};

function Component({
  title,
  subtitle,
  buttonText,
  backgroundColor,
  textColor,
  onClick,
}: Props) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card
      backgroundColor={backgroundColor ? backgroundColor : colors.green200}
      color={textColor ? textColor : colors.neutral100}
    >
      <Stack direction={isSm ? 'column' : 'row'}>
        <Stack pr={{ xs: '16px', sm: '100px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <UserDashboardCRCHeader title={title} subtitle={subtitle} />
          </Box>

          <Box>
            <Button
              variant={'contained'}
              size="large"
              sx={{
                color: colors.neutral800,
                backgroundColor: 'white',
                ':hover': {
                  bgcolor: colors.neutral200,
                },
                width: '100%',
                height: '40px',
                mt: '36px',
                mb: '30px',
              }} //mb:114
              onClick={onClick}
            >
              <Typography variant="action_small">{buttonText}</Typography>
            </Button>
          </Box>
        </Stack>
        <CardMedia
          image={dashboardImages.crc_white}
          component="img"
          sx={{
            p: { xs: 2, md: 1 },
            mr: 4,
            // marginTop: '20px',
            objectFit: 'contain',
            height: '180px',
            width: '180px',
          }}
        />
      </Stack>
    </Card>
  );
}

export default Component;
