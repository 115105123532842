import React from 'react';
import Typography from 'components/common/Typography';
import Link from 'components/common/Link/Link';

import Tooltip from 'components/common/Tooltip/Tooltip';
import colors from 'constants/colors';

type Props = {
  tags: object;
  handleCompanyFilterChange: (
    e: React.MouseEvent<HTMLElement>,
    tags: object
  ) => void;
};

function TagButton({ tags, handleCompanyFilterChange }: Props) {
  return (
    <>
      {Object.entries(tags).map((filterObjArray, index) => {
        let readableText: string = `${filterObjArray[1]}`;

        return (
          <Link
            key={index}
            component="button"
            value={filterObjArray[0]}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              handleCompanyFilterChange(e, tags)
            }
            sx={{
              backgroundColor: colors.neutral100,
              maxWidth: { xs: '200px', sm: '280px' },
              borderRadius: '8px',

              marginRight: '6px',
              marginBottom: '6px',
              padding: '4px',
              textTransform: 'none',
              letterSpacing: '0.4px',
              '&:hover': {
                backgroundColor: 'neutral.main',
              },
              overflow: 'hidden',
              wordWrap: 'break-word',
            }}
            underline="none"
          >
            {readableText.length > 42 ? (
              <Tooltip title={readableText}>
                <div>
                  <Typography
                    noWrap
                    display="block"
                    variant="caption"
                    color={colors.neutral600}
                    textAlign={'left'}
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {readableText}
                  </Typography>
                </div>
              </Tooltip>
            ) : (
              <Typography
                noWrap
                display="block"
                variant="caption"
                color={colors.neutral600}
                textAlign={'left'}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {readableText}
              </Typography>
            )}
          </Link>
        );
      })}
    </>
  );
}

export default TagButton;
