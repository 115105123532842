import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import {
  getClimateScoreAsPercent,
  getClimateScoreCategoryScores,
} from 'utils/tools';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

import Box from 'components/common/Box';
import SelectMenu from 'components/common/SelectMenu';
import { useUserCompanyCollections } from 'hooks/useUserCompanyCollections';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import Check from 'components/icons/Check';
import Download from '@mui/icons-material/Download';
import Link from 'components/common/Link';
import colors from 'constants/colors';
import { fontWeight } from 'constants/typography';
import LinearProgress from 'components/common/LinearProgress';
import { ChevronRight } from '@mui/icons-material';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import useCompanySectors from 'hooks/useCompanySectors';
import Chip from 'components/common/Chip';

import Container from 'components/common/Container';
import CollectionDownloadButton from './CollectionDownloadButton';
import Collection from './Collection';
import CollectionBreadcrumbs from './CollectionBreadcrumbs';
import Company from '../Company/Company';
import useCompany from 'hooks/useCompanyNEW';
import CompanyDetailsPage from '../Company/CompanyDetailsPage/CompanyDetailsPage';

function UserDashboardCollections() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const {
    userCompany,
    userCompanyCPALink,
    loadUserCompany,
    hasLoadedUserCompany,
    isLoadingUserCompany,
  } = useUserCompany();

  const [searchParams] = useSearchParams();
  const { collectionId, companyInCollectionSlug } = useParams();
  const showExample = collectionId === 'example';

  const { isLoading, data: collections, isError } = useUserCompanyCollections();

  const collectionIndex = collections?.findIndex(
    (d: any) => d.id === collectionId
  );
  const collection =
    !collections || collectionIndex === -1
      ? collections
        ? collections[0]
        : {}
      : collections[collectionIndex];

  const { isLoading: isLoadingOverview, data: overviewData } =
    useUserCompanyCollectionOverview({
      collection_id: showExample ? undefined : collection?.id,
    });

  const reportingCompanyInCollectionSlug = overviewData?.find(
    (i: any) => i.company_slug === companyInCollectionSlug
  )?.company_slug;
  const { data: companyInCollectiondData } = useCompany(
    companyInCollectionSlug
  );

  useEffect(() => {
    if (!isLoadingUserCompany && !hasLoadedUserCompany) {
      loadUserCompany({ auth });
    }
  }, [auth, isLoadingUserCompany, hasLoadedUserCompany, loadUserCompany]);

  return (
    <Container pageContainer>
      <CollectionBreadcrumbs
        collectionId={collection?.id}
        collectionName={showExample ? t('Example') : collection?.name}
        parentCollectionId={collection?.parent}
        parentCollectionName={
          collections?.find((c: any) => c.id === collection.parent)?.name
        }
        companyInCollectionSlug={companyInCollectionSlug}
      />

      {companyInCollectionSlug ? (
        <CompanyDetailsPage companyData={companyInCollectiondData} />
      ) : (
        <Collection />
      )}
    </Container>
  );
}

export default observer(UserDashboardCollections);
