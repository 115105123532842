import { observer } from 'mobx-react-lite';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import tccPalette from './tccPalette';
import tccPaletteDark from './tccPaletteDark';
import tccTypography from './tccTypography';

import usePreferences from 'hooks/usePreferences';
import colors from 'constants/colors';

const getTheme = ({ darkMode }: { darkMode: boolean }) =>
  createTheme({
    shape: {
      borderRadius: 16,
    },
    typography: { ...tccTypography },
    palette: { ...(darkMode ? tccPaletteDark : tccPalette) },
    components: {
      MuiButton: {
        styleOverrides: {
          text: {
            textTransform: 'none',
            fontWeight: 'bold',
            paddingRight: '12px',
            paddingLeft: '12px',
          },
          contained: {
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.03)',
            '&:hover': {
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.05)',
            },
          },
          outlined: {
            textTransform: 'none',
            fontWeight: 'bold',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: '8px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: colors.neutral100,
            borderRadius: '16px !important',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: colors.neutral100,
            borderRadius: '16px !important',
          },
        },
      },
    },
  });

const TccThemeProvider = ({ children }: any) => {
  const preferences = usePreferences();
  const theme = getTheme({ darkMode: preferences.darkMode });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default observer(TccThemeProvider);

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    hover: PaletteColor;
    neutral: {
      800: PaletteColor;
      700: PaletteColor;
      600: PaletteColor;
      500: PaletteColor;
      400: PaletteColor;
      200: PaletteColor;
      100: PaletteColor;
    };
    onSurface: {
      100: PaletteColor;
    };
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption2: React.CSSProperties;
    action_small: React.CSSProperties;
    action_medium: React.CSSProperties;
  }
  interface ButtonVariants {
    neutral: React.CSSProperties['color'];
    onSurface: React.CSSProperties['color'];
  }

  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
    action_small?: React.CSSProperties;
    action_medium?: React.CSSProperties;
  }
  interface ButtonVariantsOptions {
    neutral?: React.CSSProperties['color'];
    onSurface?: React.CSSProperties['color'];
  }
}
declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
    action_small: true;
    action_medium: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    whiteBackground: true;
    formBackButton: true;
  }
  interface ButtonPropsColorOverrides {
    neutral: true;
    onSurface: true;
  }
}
