import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionListDataGrid from './CollectionListDataGrid';

function UserCompanyCollectionList({
  collectionId,
  hasCollections,
  data,
  isSupplierCollection = false,
  isFiltered = false,
}: {
  collectionId?: string;
  hasCollections?: boolean;
  data: any[];
  isSupplierCollection?: boolean;
  isFiltered?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();

  const { isLoading } = useUserCompanyCollectionOverview({
    collection_id: collectionId !== 'example' ? collectionId : undefined,
  });

  return (
    <CollectionListDataGrid
      isSupplierCollection={isSupplierCollection}
      collectionId={collectionId}
      rows={data}
      loading={!(data.length > 0) && hasCollections && isLoading}
      isFiltered={isFiltered}
    />
  );
}

export default UserCompanyCollectionList;
