import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import useRFIDialog from './store/useRFIDialog';

import Stack from 'components/common/Stack';
import Loading from 'components/common/Loading';
import Grid from 'components/common/Grid';
import Button from 'components/common/Button';
import Typography from 'components/common/Typography';
import BootstrapDialogTitle from 'components/common/BootstrapDialogTitle';
import DialogActions from 'components/common/DialogActions';
import Box from 'components/common/Box';
import TccInputTextField from 'components/common/TccInputTextField';

import { MailOutlineIcon, AttachIcon } from 'components/icons/mui_icons';

const RFIForm = ({
  onClose = () => false,
  identifier,
  subject,
  companyName = '',
  userDetails,
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const snackbarMessage = useSnackbarMessage();
  const { onSubmit, isSubmitting, form, hasInitialized, init } = useRFIDialog();

  React.useEffect(() => {
    if (!hasInitialized) {
      init({ auth, identifier });
      form.setValue(
        'message_sub',
        `<strong>${t('Message Subject')}:</strong><br> ${subject}`
      );
    }
    form.setValue(
      'subject',
      `${subject} - ${'Support request from'} ${companyName}`
    );
  });

  const customBodyText = `<strong>${t(
    'Email sent by'
  )}:</strong> ${auth.userDataForm.values.get(
    'first_name'
  )} ${auth.userDataForm.values.get('last_name')}<br>
  <strong>${t('User Email')}:</strong> ${userDetails.email}<br>
  ${form.values.get('message_sub')}<br>
  ${form.values.get('message')}`;

  const setDefaultValue = (prop, text) => {
    if (form.values.get(prop) === undefined) {
      form.setValue(prop, text);
    }
  };
  return !hasInitialized ? (
    <Box sx={{ display: 'flex', width: '50px', height: '50px' }}>
      <Loading color="primary" />
    </Box>
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        form.setValue('body', customBodyText);
        onSubmit({ auth, snackbarMessage, onClose });
      }}
      onKeyPress={(e) => {
        if (e.shiftKey && e.key === 'Enter') {
          return;
        } else if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <Box padding={2}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <Grid>
            <Typography variant={'h4'} color={'black'} mb={'10px'}>
              {t(`Contact us`)}
            </Typography>
            <Typography variant={'body1'} color={'black'} mb={'10px'}>
              {t(
                `Please complete the details below and one of our analysts will be in touch to help you with your request.`
              )}
            </Typography>
          </Grid>
        </BootstrapDialogTitle>
        <Stack spacing={2} padding={2}>
          <TccInputTextField
            title={t(`Message Subject`)}
            type="text"
            placeholder={subject}
            error={form.errors.has('message_sub')}
            onChange={(e) =>
              form.setValue(
                'message_sub',
                `<strong>${t('Message Subject')}:</strong><br> ${
                  e.target.value
                }`
              )
            }
            onSubmit={setDefaultValue(
              'message_sub',
              `<strong>${t('Message Subject')}:</strong><br> `
            )}
          />

          <TccInputTextField
            title={t(`Your Message`)}
            type="text"
            multiline
            rows={18}
            characterLimit={2000}
            placeholder={t('press Shift+Enter to change line')}
            error={form.errors.has('message')}
            onChange={(e) =>
              form.setValue(
                'message',
                `<strong>${t('Message')}:</strong><br> ${e.target.value}`
              )
            }
            onSubmit={setDefaultValue(
              'message',
              `<strong>${t('Message')}:</strong><br> `
            )}
          />

          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <label htmlFor="attachments-upload">
              <input
                hidden
                type="file"
                id="attachments-upload"
                multiple
                onChange={(e) => {
                  form.setValue('attachments', Array.from(e.target.files));
                }}
              />
              <Button
                color="inherit"
                component="span"
                disabled={isSubmitting || form.hasErrors}
                startIcon={<AttachIcon />}
              >
                {`${t('Attach file')} ${
                  !!form.values.get('attachments')
                    ? `(${form.values.get('attachments').length})`
                    : ''
                }`}
              </Button>
            </label>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || form.hasErrors}
                startIcon={<MailOutlineIcon />}
              >
                {t('Send email')}
              </Button>
            </DialogActions>
          </Stack>
        </Stack>
      </Box>
    </form>
  );
};

export default observer(RFIForm);
