import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import useUserCompany from 'hooks/useUserCompany';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';
import Stack from 'components/common/Stack';
import AppLoading from 'components/common/AppLoading';
import exampleData from './exampleData';
import Box from 'components/common/Box';
import CollectionOverviewList from './CollectionList';
import { useUserCompanyCollections } from 'hooks/useUserCompanyCollections';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import colors from 'constants/colors';
import Link from 'components/common/Link';
import CollectionWidgetCountries from './CollectionWidgetCountries';
import {
  SearchOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ChevronLeft,
  ArrowOutwardOutlined,
} from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import WidgetCard from './CollectionCard';
import CollectionsUpsell from '../Collections/CollectionsUpsell';
import { CloseIcon } from 'components/icons/mui_icons';
import Close from 'components/icons/Close';
import {
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Select,
  Autocomplete,
  Collapse,
} from '@mui/material';
import { getCountryName } from 'utils/getCountryName';
import IconButton from 'components/common/IconButton';
import CollectionWidgetCard from './CollectionCard';
import CollectionPieChart from './CollectionPieChart';
import CollectionDownloadButton from './CollectionDownloadButton';
import CollectionFilterCarousel from './CollectionFilterCarousel';
import Container from 'components/common/Container';
import { letterSpacing } from 'constants/typography';

function UserDashboardCollection() {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { userCompany } = useUserCompany();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const showExample = collectionId === 'example';

  // Display mode state remains local.
  const [displayMode, setDisplayMode] = useState<'number' | 'percent'>(
    'number'
  );
  const handleDisplayModeChange = (
    _: React.MouseEvent<HTMLElement>,
    newMode: 'number' | 'percent' | null
  ) => {
    if (newMode !== null) setDisplayMode(newMode);
  };

  // Remove local filter states.
  // Instead, we use URL search parameters for all filters:
  const [searchParams, setSearchParams] = useSearchParams();

  // Helper to update a filter in the URL.
  const updateFilter = (key: string, value: number | string | null) => {
    const newParams = new URLSearchParams(searchParams);
    if (value === null || value === '') {
      newParams.delete(key);
    } else {
      newParams.set(key, value.toString());
    }
    setSearchParams(newParams);
  };

  // Derive filter values from URL search params.
  const maturityFilter = searchParams.get('maturity')
    ? parseInt(searchParams.get('maturity') as string)
    : null;
  const targetsFilter = searchParams.get('targets')
    ? parseInt(searchParams.get('targets') as string)
    : null;
  const ccfFilter = searchParams.get('ccf')
    ? parseInt(searchParams.get('ccf') as string)
    : null;
  const pcfFilter = searchParams.get('pcf')
    ? parseInt(searchParams.get('pcf') as string)
    : null;
  const intensityFilter = searchParams.get('intensity')
    ? parseInt(searchParams.get('intensity') as string)
    : null;
  const dataAccessFilter = searchParams.get('dataAccess')
    ? parseInt(searchParams.get('dataAccess') as string)
    : null;
  const publicDataFilter = searchParams.get('publicData')
    ? parseInt(searchParams.get('publicData') as string)
    : null;
  const privateDataFilter = searchParams.get('privateData')
    ? parseInt(searchParams.get('privateData') as string)
    : null;
  const searchText = searchParams.get('search') || '';
  const countryFilter = searchParams.get('country')
    ? searchParams.get('country')!.split(',')
    : [];

  // Collections & overview
  const {
    isLoading,
    isFetching,
    data: collections,
    isError,
  } = useUserCompanyCollections();

  const collectionNames = collections
    ? collections.map((d: any) => d.name)
    : [];
  const hasCollections = collectionNames.length > 0;
  const collectionIndex = collections?.findIndex(
    (d: any) => d.id === collectionId
  );
  const collection =
    !collections || collectionIndex === -1
      ? collections
        ? collections[0]
        : {}
      : collections[collectionIndex];

  const { data: collectionOverview, isLoading: isLoadingOverview } =
    useUserCompanyCollectionOverview({
      collection_id: showExample ? undefined : collection?.id,
    });
  const dataToUse = showExample
    ? exampleData
    : collectionOverview
    ? collectionOverview
    : [];

  // Helper to dedupe companies by name.
  const dedupeByName = (data: any[]): any[] => {
    const seenNames = new Set<string>();
    return data.filter((item) => {
      if (!item) return false;
      if (seenNames.has(item.company_name)) return false;
      seenNames.add(item.company_name);
      return true;
    });
  };

  const maturityRanking = ['POOR', 'FAIR', 'GOOD'];
  const getMaturity = (d: any) => {
    const rcM =
      d.reporting_company_maturity_level &&
      !!d.reporting_company_public_data_screening_last_date
        ? d.reporting_company_maturity_level
        : undefined;
    const cM =
      d.company_maturity_level && !!d.company_public_data_screening_last_date
        ? d.company_maturity_level
        : undefined;
    if (rcM || cM) {
      const rcIndex = rcM ? maturityRanking.indexOf(rcM) : -1;
      const cIndex = cM ? maturityRanking.indexOf(cM) : -1;
      const highestIndex = Math.max(rcIndex, cIndex);
      return maturityRanking[highestIndex];
    } else {
      return (
        d.reporting_company_maturity_level_crc || d.company_maturity_level_crc
      );
    }
  };

  const getClimateScore = (d: any) => {
    const score1 = d.reporting_company_climate_score_overall || 0;
    const score2 = d.company_climate_score_overall || 0;
    return Math.max(score1, score2);
  };

  const collectionData = dedupeByName(dataToUse).map((d: any) => ({
    id: d.company_uuid,
    company_name: d.company_name,
    company_slug: d.company_slug,
    company_sector: d.company_sector,
    company_country: d.company_country,
    company_logo: d.company_logo,
    company_website: d.company_website,
    reporting_company_name: d.reporting_company_name,
    reporting_company_slug: d.reporting_company_slug,
    reporting_company_sector: d.reporting_company_sector,
    reporting_company_country: d.reporting_company_country,
    climate_score: getClimateScore(d),
    maturity_level: getMaturity(d),
    maturity_level_self_reported:
      d.reporting_company_maturity_level_crc || d.company_maturity_level_crc,
    public_data_available: !!(
      d.reporting_company_has_public_data || d.company_has_public_data
    ),
    public_data_screening_status:
      d.reporting_company_public_data_screening_status ||
      d.company_public_data_screening_status,
    public_data_screening_last_date:
      d.reporting_company_public_data_screening_last_date ||
      d.company_public_data_screening_last_date,
    private_data_status:
      d.reporting_company_private_data_status || d.company_private_data_status,
    private_data_user:
      d.reporting_company_private_data_user || d.company_private_data_user,
    private_data_cpa_status:
      d.reporting_company_private_data_cpa_status ||
      d.company_private_data_cpa_status,
    private_data_cpa_last_date:
      d.reporting_company_private_data_cpa_last_date ||
      d.company_private_data_cpa_last_date,
    private_data_crc_last_date:
      d.reporting_company_private_data_crc_last_date ||
      d.company_private_data_crc_last_date,
    has_scope_1: d.company_has_scope_1 || d.reporting_company_has_scope_1,
    has_scope_2: d.company_has_scope_2 || d.reporting_company_has_scope_2,
    has_scope_3: d.company_has_scope_3 || d.reporting_company_has_scope_3,
    has_emission_intensities:
      d.company_has_emission_intensities ||
      d.reporting_company_has_emission_intensities,
    has_pcf: d.company_has_pcf || d.reporting_company_has_pcf,
    has_targets: d.company_has_targets || d.reporting_company_has_targets,
    has_sbti_targets:
      d.company_has_sbti_targets || d.reporting_company_has_sbti_targets,
  }));

  // Update filtering to use searchParams derived values.
  const applyAllFilters = (data: any[]): any[] => {
    let filtered = data.slice();
    if (ccfFilter !== null) {
      if (ccfFilter === 0) {
        filtered = filtered.filter(
          (c) => c.has_scope_1 && c.has_scope_2 && c.has_scope_3
        );
      } else if (ccfFilter === 1) {
        filtered = filtered.filter(
          (c) => c.has_scope_1 && c.has_scope_2 && !c.has_scope_3
        );
      } else if (ccfFilter === 2) {
        filtered = filtered.filter((c) => !(c.has_scope_1 && c.has_scope_2));
      }
    }
    if (intensityFilter !== null) {
      if (intensityFilter === 0) {
        filtered = filtered.filter((c) => c.has_emission_intensities);
      } else if (intensityFilter === 1) {
        filtered = filtered.filter((c) => !c.has_emission_intensities);
      }
    }
    if (pcfFilter !== null) {
      if (pcfFilter === 0) {
        filtered = filtered.filter((c) => c.has_pcf);
      } else if (pcfFilter === 1) {
        filtered = filtered.filter((c) => !c.has_pcf);
      }
    }
    if (publicDataFilter !== null) {
      if (publicDataFilter === 0) {
        filtered = filtered.filter((c) => !!c.public_data_screening_last_date);
      } else if (publicDataFilter === 1) {
        filtered = filtered.filter((c) => !c.public_data_screening_last_date);
      }
    }
    if (privateDataFilter !== null) {
      if (privateDataFilter === 0) {
        filtered = filtered.filter(
          (c) =>
            c.private_data_user &&
            !(
              c.private_data_cpa_status === 'PENDING_RESPONSE' ||
              c.private_data_cpa_status === 'EXPIRED'
            ) &&
            !!c.private_data_cpa_last_date
        );
      } else if (privateDataFilter === 1) {
        filtered = filtered.filter(
          (c) =>
            c.private_data_user &&
            (c.private_data_cpa_status === 'PENDING_RESPONSE' ||
              c.private_data_cpa_status === 'EXPIRED')
        );
      } else if (privateDataFilter === 2) {
        filtered = filtered.filter(
          (c) =>
            c.private_data_user &&
            !(
              c.private_data_cpa_status === 'PENDING_RESPONSE' ||
              c.private_data_cpa_status === 'EXPIRED'
            ) &&
            !!c.private_data_crc_last_date &&
            !c.private_data_cpa_last_date
        );
      } else if (privateDataFilter === 3) {
        filtered = filtered.filter(
          (c) =>
            c.private_data_user &&
            c.private_data_cpa_status !== 'PENDING_RESPONSE' &&
            !c.private_data_crc_last_date
        );
      } else if (privateDataFilter === 4) {
        filtered = filtered.filter((c) => !c.private_data_user);
      }
    }
    if (dataAccessFilter !== null) {
      if (dataAccessFilter === 0) {
        filtered = filtered.filter(
          (c) => c.private_data_status === 'ACCESS_GRANTED'
        );
      } else if (dataAccessFilter === 1) {
        filtered = filtered.filter(
          (c) => c.private_data_status === 'ACCESS_REQUESTED'
        );
      } else if (dataAccessFilter === 2) {
        filtered = filtered.filter(
          (c) =>
            c.private_data_user &&
            !(
              c.private_data_status === 'ACCESS_GRANTED' ||
              c.private_data_status === 'ACCESS_REQUESTED'
            )
        );
      }
    }
    if (maturityFilter !== null) {
      if (maturityFilter === 0) {
        filtered = filtered.filter((c) => c.maturity_level === 'GOOD');
      } else if (maturityFilter === 1) {
        filtered = filtered.filter((c) => c.maturity_level === 'FAIR');
      } else if (maturityFilter === 2) {
        filtered = filtered.filter((c) => c.maturity_level === 'POOR');
      } else if (maturityFilter === 3) {
        filtered = filtered.filter(
          (c) =>
            c.maturity_level !== 'GOOD' &&
            c.maturity_level !== 'FAIR' &&
            c.maturity_level !== 'POOR'
        );
      }
    }
    if (searchText) {
      const lowerSearch = searchText.toLowerCase();
      filtered = filtered.filter((c) => {
        const companyName = c.company_name ? c.company_name.toLowerCase() : '';
        const reportingCompanyName = c.reporting_company_name
          ? c.reporting_company_name.toLowerCase()
          : '';
        return (
          companyName.includes(lowerSearch) ||
          reportingCompanyName.includes(lowerSearch)
        );
      });
    }
    if (countryFilter.length > 0) {
      filtered = filtered.filter(
        (c) =>
          countryFilter.includes(c.company_country) ||
          countryFilter.includes(c.reporting_company_country)
      );
    }
    if (targetsFilter !== null) {
      if (targetsFilter === 0) {
        filtered = filtered.filter((c) => c.has_sbti_targets);
      } else if (targetsFilter === 1) {
        filtered = filtered.filter((c) => !c.has_sbti_targets && c.has_targets);
      } else if (targetsFilter === 2) {
        filtered = filtered.filter(
          (c) => !c.has_sbti_targets && !c.has_targets
        );
      }
    }
    return filtered;
  };

  const globalFilteredData = applyAllFilters(collectionData);

  // Breakdown counts for widgets (using filtered data)
  const ccfAllCount = globalFilteredData.filter(
    (c) => c.has_scope_1 && c.has_scope_2 && c.has_scope_3
  ).length;
  const ccfScope1And2Count = globalFilteredData.filter(
    (c) => c.has_scope_1 && c.has_scope_2 && !c.has_scope_3
  ).length;
  const noCCFCount = globalFilteredData.filter(
    (c) => !(c.has_scope_1 && c.has_scope_2)
  ).length;
  const goodCount = globalFilteredData.filter(
    (c) => c.maturity_level === 'GOOD'
  ).length;
  const fairCount = globalFilteredData.filter(
    (c) => c.maturity_level === 'FAIR'
  ).length;
  const poorCount = globalFilteredData.filter(
    (c) => c.maturity_level === 'POOR'
  ).length;
  const noMaturityCount = globalFilteredData.filter(
    (c) =>
      c.maturity_level !== 'GOOD' &&
      c.maturity_level !== 'FAIR' &&
      c.maturity_level !== 'POOR'
  ).length;
  const sbtiCount = globalFilteredData.filter((c) => c.has_sbti_targets).length;
  const targetsCount = globalFilteredData.filter(
    (c) => !c.has_sbti_targets && c.has_targets
  ).length;
  const noTargetsCount = globalFilteredData.filter(
    (c) => !c.has_sbti_targets && !c.has_targets
  ).length;
  const publicDataCount = globalFilteredData.filter(
    (c) => !!c.public_data_screening_last_date
  ).length;
  const noPublicDataCount = globalFilteredData.filter(
    (c) => !c.public_data_screening_last_date
  ).length;
  const completedAssessmentCount = globalFilteredData.filter(
    (c) =>
      c.private_data_user &&
      !(
        c.private_data_cpa_status === 'PENDING_RESPONSE' ||
        c.private_data_cpa_status === 'EXPIRED'
      ) &&
      !!c.private_data_cpa_last_date
  ).length;
  const pendingAssessmentCount = globalFilteredData.filter(
    (c) =>
      c.private_data_user &&
      (c.private_data_cpa_status === 'PENDING_RESPONSE' ||
        c.private_data_cpa_status === 'EXPIRED')
  ).length;
  const noAssessmentCount = globalFilteredData.filter(
    (c) =>
      c.private_data_user &&
      !(
        c.private_data_cpa_status === 'PENDING_RESPONSE' ||
        c.private_data_cpa_status === 'EXPIRED'
      ) &&
      !!c.private_data_crc_last_date &&
      !c.private_data_cpa_last_date
  ).length;
  const pendingRegistrationCount = globalFilteredData.filter(
    (c) =>
      c.private_data_user &&
      !(
        c.private_data_cpa_status === 'PENDING_RESPONSE' ||
        c.private_data_cpa_status === 'EXPIRED'
      ) &&
      !(c.private_data_crc_last_date || c.private_data_cpa_last_date)
  ).length;
  const noUserCount = globalFilteredData.filter(
    (c) => !c.private_data_user
  ).length;
  const pcfCount = globalFilteredData.filter((c) => c.has_pcf).length;
  const noPcfCount = globalFilteredData.filter((c) => !c.has_pcf).length;
  const intensityCount = globalFilteredData.filter(
    (c) => c.has_emission_intensities
  ).length;
  const noIntensityCount = globalFilteredData.filter(
    (c) => !c.has_emission_intensities
  ).length;
  const dataAccessCount = globalFilteredData.filter(
    (c) => c.private_data_status === 'ACCESS_GRANTED'
  ).length;
  const dataAccessRequestCount = globalFilteredData.filter(
    (c) => c.private_data_status === 'ACCESS_REQUESTED'
  ).length;
  const noDataAccessCount = globalFilteredData.filter(
    (c) =>
      c.private_data_user &&
      !(
        c.private_data_status === 'ACCESS_GRANTED' ||
        c.private_data_status === 'ACCESS_REQUESTED'
      )
  ).length;

  const uniqueCountries = Array.from(
    new Set(
      collectionData.flatMap((item) => {
        const countries = [];
        if (item.company_country) {
          countries.push(item.company_country);
        }
        if (item.reporting_company_country) {
          countries.push(item.reporting_company_country);
        }
        return countries;
      })
    )
  );
  const filteredUniqueCountries = Array.from(
    new Set(
      globalFilteredData.flatMap((item) => {
        const countries = [];
        if (item.company_country) {
          countries.push(item.company_country);
        }
        if (item.reporting_company_country) {
          countries.push(item.reporting_company_country);
        }
        return countries;
      })
    )
  );

  const isFiltered =
    !!searchText ||
    countryFilter.length > 0 ||
    maturityFilter !== null ||
    targetsFilter !== null ||
    ccfFilter !== null ||
    pcfFilter !== null ||
    intensityFilter !== null ||
    dataAccessFilter !== null ||
    publicDataFilter !== null ||
    privateDataFilter !== null;

  // Active filter chips based on URL params.
  const activeFilters: {
    key: string;
    label: string;
    onDelete: () => void;
  }[] = [];
  if (searchText) {
    activeFilters.push({
      key: 'search',
      label: `${t('Search')}: ${searchText}`,
      onDelete: () => updateFilter('search', null),
    });
  }
  if (countryFilter.length > 0) {
    activeFilters.push({
      key: 'country',
      label: `${t('Countries')}: ${countryFilter
        .map(getCountryName)
        .join(', ')}`,
      onDelete: () => updateFilter('country', null),
    });
  }
  if (maturityFilter !== null) {
    const labels = [t('Good'), t('Fair'), t('Poor'), t('None')];
    activeFilters.push({
      key: 'maturity',
      label: `${t('Climate Maturity')}: ${labels[maturityFilter]}`,
      onDelete: () => updateFilter('maturity', null),
    });
  }
  if (targetsFilter !== null) {
    const labels = [t('SBTi'), t('Other'), t('None')];
    activeFilters.push({
      key: 'targets',
      label: `${t('Targets')}: ${labels[targetsFilter]}`,
      onDelete: () => updateFilter('targets', null),
    });
  }
  if (ccfFilter !== null) {
    const labels = [t('Scope 1+2+3'), t('Scope 1+2'), t('None')];
    activeFilters.push({
      key: 'ccf',
      label: `${t('Corporate Carbon Footprints')}: ${labels[ccfFilter]}`,
      onDelete: () => updateFilter('ccf', null),
    });
  }
  if (pcfFilter !== null) {
    const labels = [t('Yes'), t('No')];
    activeFilters.push({
      key: 'pcf',
      label: `${t('Product Carbon Footprints')}: ${labels[pcfFilter]}`,
      onDelete: () => updateFilter('pcf', null),
    });
  }
  if (intensityFilter !== null) {
    const labels = [t('Yes'), t('No')];
    activeFilters.push({
      key: 'intensity',
      label: `${t('Emission Intensities')}: ${labels[intensityFilter]}`,
      onDelete: () => updateFilter('intensity', null),
    });
  }
  if (dataAccessFilter !== null) {
    const labels = [t('Access'), t('Access Requested'), t('No Access')];
    activeFilters.push({
      key: 'dataAccess',
      label: `${t('Confidential Data Access')}: ${labels[dataAccessFilter]}`,
      onDelete: () => updateFilter('dataAccess', null),
    });
  }
  if (publicDataFilter !== null) {
    const labels = [t('Screened'), t('Not Screened')];
    activeFilters.push({
      key: 'publicData',
      label: `${t('Public Data Screening')}: ${labels[publicDataFilter]}`,
      onDelete: () => updateFilter('publicData', null),
    });
  }
  if (privateDataFilter !== null) {
    const labels = [
      t('Completed Assessment'),
      t('Pending Assessment'),
      t('No Assessment'),
      t('Pending Registration'),
      t('Not Available'),
    ];
    activeFilters.push({
      key: 'privateData',
      label: `${t('Confidential Responses')}: ${labels[privateDataFilter]}`,
      onDelete: () => updateFilter('privateData', null),
    });
  }

  const clearAllFilters = () => {
    setSearchParams(new URLSearchParams());
  };

  const [showMore, setShowMore] = useState(false);

  const childCollections =
    collections?.filter((c: any) => c.parent === collection?.id) || [];

  if (isLoading || isLoadingOverview) return <AppLoading />;
  if (isError)
    return <Alert severity="error">{t('Something went wrong')}</Alert>;

  return (
    <Container disableGutters>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <Stack spacing={1} flex={1}>
              <Typography
                variant="h4"
                sx={{ fontSize: '40px' }}
                color={colors.neutral800}
              >
                {showExample ? t('Example') : collection?.name}
              </Typography>
            </Stack>
            {collection?.id && (
              <Box>
                <CollectionDownloadButton
                  disabled={showExample}
                  collectionId={collection?.id}
                />
              </Box>
            )}
          </Stack>

          {childCollections?.length > 0 ? (
            <Stack spacing={0}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  opacity: 0.4,
                }}
              >
                {t('Lists')}
              </Typography>
              <Stack direction={'row'} spacing={1}>
                {childCollections.map((c: any, i: number) => {
                  return (
                    <Link
                      key={i}
                      underline="hover"
                      color="inherit"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/collections/${c.id}`)}
                    >
                      {c.name +
                        `${childCollections.length - 1 !== i ? ',' : ''}`}
                    </Link>
                  );
                })}
              </Stack>
            </Stack>
          ) : null}
        </Stack>

        <Stack spacing={3} direction={'row'}>
          {/* <Box sx={{ width: '200px' }} /> */}
          <Stack spacing={{ xs: 2, sm: 3 }} sx={{ width: 'calc(100% - 0px)' }}>
            <Box>
              <Stack spacing={1} overflow={'hidden'}>
                <Collapse in={showMore} collapsedSize="230px" timeout="auto">
                  <Stack
                    sx={{ gap: 1 }}
                    direction={'row'}
                    maxWidth={'100%'}
                    flexWrap={'wrap'}
                  >
                    <WidgetCard
                      minWidth="300px"
                      title={
                        countryFilter.length === 0
                          ? uniqueCountries.length + ''
                          : countryFilter.length + ''
                      }
                      caption={
                        <Stack
                          direction={'row'}
                          spacing={2}
                          alignItems={'flex-end'}
                        >
                          <Typography
                            variant={'body1'}
                            sx={{
                              flex: 1,
                              fontWeight: 100,
                              color: colors.neutral600,
                              textTransform: 'none',
                              textAlign: 'left',
                            }}
                          >
                            {t('Countries', {
                              count:
                                countryFilter.length === 0
                                  ? uniqueCountries.length
                                  : countryFilter.length,
                            })}
                          </Typography>
                        </Stack>
                      }
                      component={
                        <Box overflow={'visible'} height={'100%'}>
                          <Box
                            minWidth={'210px'}
                            maxHeight={'0px'}
                            maxWidth={'320px'}
                            mt={-2}
                            ml={0}
                          >
                            <CollectionWidgetCountries
                              countries={filteredUniqueCountries}
                              selectedCountries={countryFilter || undefined}
                            />
                          </Box>
                        </Box>
                      }
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('Good'), t('Fair'), t('Poor'), t('None')]}
                          data={[
                            goodCount,
                            fairCount,
                            poorCount,
                            noMaturityCount,
                          ]}
                          tooltipChecklistData={[
                            [
                              { label: t('Has Scope 1 Data'), value: true },
                              { label: t('Has Scope 2 Data'), value: true },
                              { label: t('Has Targets'), value: true },
                            ],
                            [
                              { label: t('Has Scope 1 Data'), value: true },
                              { label: t('Has Scope 2 Data'), value: true },
                              { label: t('Has Targets'), value: false },
                            ],
                            [
                              { label: t('Has Scope 1 Data'), value: false },
                              { label: t('Has Scope 2 Data'), value: false },
                            ],
                            [{ label: t('No Data'), value: false }],
                          ]}
                          colors={[
                            colors.green300,
                            colors.warning100,
                            colors.tertiary100,
                            colors.neutral200,
                          ]}
                          selectedFilter={maturityFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'maturity',
                              index === maturityFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Climate Maturity')}
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('SBTi'), t('Other'), t('None')]}
                          data={[sbtiCount, targetsCount, noTargetsCount]}
                          colors={[
                            colors.green300,
                            colors.warning100,
                            colors.neutral200,
                          ]}
                          selectedFilter={targetsFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'targets',
                              index === targetsFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Targets')}
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('Scope 1+2+3'), t('Scope 1+2'), t('None')]}
                          data={[ccfAllCount, ccfScope1And2Count, noCCFCount]}
                          colors={[
                            colors.green300,
                            colors.blue200,
                            colors.neutral200,
                          ]}
                          selectedFilter={ccfFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'ccf',
                              index === ccfFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Corporate Carbon Footprints')}
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('Yes'), t('No')]}
                          data={[pcfCount, noPcfCount]}
                          colors={[colors.green300, colors.neutral200]}
                          selectedFilter={pcfFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'pcf',
                              index === pcfFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Product Carbon Footprints')}
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('Yes'), t('No')]}
                          data={[intensityCount, noIntensityCount]}
                          colors={[colors.green300, colors.neutral200]}
                          selectedFilter={intensityFilter}
                          onFilterSelect={(index: number) =>
                            updateFilter(
                              'intensity',
                              index === intensityFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Emission Intensities')}
                    />
                    <WidgetCard
                      minWidth="300px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[
                            t('Access'),
                            t('Access Requested'),
                            t('No Access'),
                          ]}
                          data={[
                            dataAccessCount,
                            dataAccessRequestCount,
                            noDataAccessCount,
                          ]}
                          tooltipChecklistData={[
                            [
                              {
                                label: t('Data Access granted'),
                                value: true,
                              },
                            ],
                            [
                              {
                                label: t('Data Access has been requested'),
                              },
                            ],
                            [
                              {
                                label: t('Data Access granted'),
                                value: false,
                              },
                            ],
                          ]}
                          colors={[
                            colors.green300,
                            colors.warning100,
                            colors.neutral200,
                          ]}
                          selectedFilter={dataAccessFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'dataAccess',
                              index === dataAccessFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Confidential Data Access')}
                    />
                    <WidgetCard
                      minWidth="370px"
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[
                            t('Completed Assessment'),
                            t('Pending Assessment'),
                            t('No Assessment'),
                            t('Pending Registration'),
                            t('Not Available'),
                          ]}
                          data={[
                            completedAssessmentCount,
                            pendingAssessmentCount,
                            noAssessmentCount,
                            pendingRegistrationCount,
                            noUserCount,
                          ]}
                          tooltipChecklistData={[
                            [
                              {
                                label: t(
                                  'Climate Performance Assessment completed'
                                ),
                                value: true,
                              },
                            ],
                            [
                              {
                                label: t(
                                  'Climate Performance Assessment in progress'
                                ),
                              },
                            ],
                            [
                              {
                                label: t(
                                  'Climate Performance Assessment has not been started'
                                ),
                                value: false,
                              },
                            ],
                            [
                              {
                                label: t(
                                  'Climate Choice platform registration in progress'
                                ),
                              },
                            ],
                          ]}
                          colors={[
                            colors.green300,
                            colors.warning100,
                            colors.tertiary100,
                            colors.neutral300,
                            colors.neutral200,
                          ]}
                          selectedFilter={privateDataFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'privateData',
                              index === privateDataFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Confidential Responses')}
                    />
                    <WidgetCard
                      component={
                        <CollectionPieChart
                          displayMode={displayMode}
                          labels={[t('Screened'), t('Not Screened')]}
                          data={[publicDataCount, noPublicDataCount]}
                          tooltipChecklistData={[
                            [
                              {
                                label: t('Public data screening completed'),
                                value: true,
                              },
                            ],
                            [
                              {
                                label: t('Public data screening completed'),
                                value: false,
                              },
                            ],
                          ]}
                          colors={[colors.green300, colors.neutral200]}
                          selectedFilter={publicDataFilter}
                          onFilterSelect={(index: number | null) =>
                            updateFilter(
                              'publicData',
                              index === publicDataFilter ? null : index
                            )
                          }
                        />
                      }
                      caption={t('Public Data Screening')}
                    />
                    <Box flex={5} minWidth={'100px'} />
                  </Stack>
                </Collapse>
                <Stack direction={'row'}>
                  <Box flex={1} />
                  <Link
                    underline="hover"
                    color="inherit"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    <Stack
                      spacing={0.5}
                      direction={'row'}
                      alignItems={'center'}
                    >
                      <Box>
                        {showMore ? t('Show less') : t('Show more metrics (9)')}
                      </Box>
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        style={{
                          transform: !showMore
                            ? 'rotate(0deg)'
                            : 'rotate(-180deg)',
                          transition: 'transform 0.3s',
                        }}
                      />
                    </Stack>
                  </Link>
                </Stack>
              </Stack>
            </Box>
            {/* Companies List */}
            <Stack spacing={1} overflow={'hidden'}>
              <Stack spacing={1.5}>
                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                  <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
                    {t('Companies')} ({globalFilteredData.length})
                  </Typography>
                  {isFiltered && (
                    <Stack
                      spacing={0.5}
                      direction={'row'}
                      alignItems={'center'}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="small"
                        sx={{ color: colors.neutral400 }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ pt: 0.25, color: colors.neutral400 }}
                      >
                        <i>{t('filtered')}</i>
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                <Stack
                  sx={{ gap: 1 }}
                  direction={'row'}
                  flexWrap={'wrap'}
                  alignItems={'center'}
                >
                  <TextField
                    sx={{
                      minWidth: '250px',
                    }}
                    color="secondary"
                    placeholder={t('Search companies')}
                    variant="outlined"
                    value={searchText}
                    inputProps={{
                      style: {
                        paddingTop: 8,
                        paddingBottom: 8,
                        minHeight: '28px',
                      },
                    }}
                    InputProps={{
                      style: {
                        paddingLeft: 10,
                        borderRadius: '8px',
                      },
                      startAdornment: (
                        <SearchOutlined
                          sx={{ color: 'inherit', mr: 1, opacity: 0.4 }}
                          fontSize="small"
                        />
                      ),
                      endAdornment: searchText ? (
                        <IconButton
                          size="small"
                          onClick={() => updateFilter('search', null)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      ) : undefined,
                    }}
                    onChange={(e) => updateFilter('search', e.target.value)}
                  />
                  <Autocomplete
                    sx={{
                      minWidth: '250px',
                    }}
                    multiple
                    size="small"
                    options={uniqueCountries}
                    value={countryFilter}
                    onChange={(event, newValue) => {
                      updateFilter('country', newValue.join(','));
                    }}
                    getOptionLabel={(option) => getCountryName(option)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          deleteIcon={
                            <CloseIcon
                              sx={{ height: '16px', width: '16px' }}
                              color="inherit"
                            />
                          }
                          label={getCountryName(option)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="secondary"
                        InputProps={{
                          style: {
                            paddingLeft: 10,
                            borderRadius: '8px',
                            minHeight: '44px',
                          },
                          startAdornment:
                            countryFilter.length === 0 ? (
                              <FilterAltOutlinedIcon
                                sx={{ color: 'inherit', opacity: 0.4 }}
                                fontSize="small"
                              />
                            ) : (
                              params?.InputProps?.startAdornment
                            ),
                          endAdornment: params?.InputProps?.endAdornment,
                          className: params?.InputProps?.className,
                          ref: params?.InputProps?.ref,
                        }}
                        variant="outlined"
                        placeholder={t('Filter countries')}
                      />
                    )}
                  />
                  {isFiltered && (
                    <>
                      {activeFilters.map((filter) => (
                        <Box key={filter.key}>
                          <Chip
                            sx={{ height: '40px !important' }}
                            label={filter.label}
                            onDelete={filter.onDelete}
                            deleteIcon={
                              <CloseIcon
                                sx={{ height: '16px', width: '16px' }}
                                color="inherit"
                              />
                            }
                          />
                        </Box>
                      ))}
                      <Button
                        startIcon={<Close fontSize="small" />}
                        sx={{ color: colors.neutral500, ml: 2 }}
                        size="small"
                        onClick={clearAllFilters}
                      >
                        {t('Clear all')}
                      </Button>
                    </>
                  )}
                  <Box flex={1} />
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <CollectionOverviewList
                  isSupplierCollection={collection?.name === 'My Suppliers'}
                  hasCollections={hasCollections}
                  collectionId={collectionId || collection?.id}
                  data={globalFilteredData}
                  isFiltered={isFiltered}
                />
              </Stack>
            </Stack>
            {showExample && (
              <Box pt={1}>
                <CollectionsUpsell />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export default observer(UserDashboardCollection);
