import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionsListDataGrid from './CollectionsListDataGrid';
import CollectionsListTable from './CollectionsListTable';
import { useUserCompanyCollections } from 'hooks/useUserCompanyCollections';
import Stack from 'components/common/Stack';
import CollectionsUpsell from './CollectionsUpsell';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { observer } from 'mobx-react-lite';
import AppLoading from 'components/common/AppLoading';

function UserCompanyCollectionsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const { isLoading, data } = useUserCompanyCollections();

  const rows =
    data?.length > 0
      ? data.map((d: any) => ({
          id: d.id,
          name: d.name,
          parent: d.parent,
        }))
      : [];

  return isLoading ? (
    <AppLoading />
  ) : (
    <Stack spacing={2}>
      <CollectionsListTable rows={rows} />
      {!isLoading && data?.length === 0 ? <CollectionsUpsell /> : null}
    </Stack>
  );
}

export default observer(UserCompanyCollectionsList);
